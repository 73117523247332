import React from "react";
import { Switch } from "@headlessui/react";

interface Props {
  value: any;
  onChange: any;
  title: string;
  description: string;
  disabled: boolean;
  textClassName?: string;
}

const classNames = (...classes: string[]) => classes.filter(Boolean).join(" ");

const Toogle = ({
  value,
  onChange,
  disabled,
  title,
  description,
  textClassName,
}: Props) => {
  return (
    <Switch.Group as="div" className="flex items-center justify-between">
      <span className="flex flex-grow flex-col">
        <Switch.Label
          as="span"
          className={`text-sm font-medium leading-6 text-gray-900 ${textClassName}`}
          passive
        >
          {title}
        </Switch.Label>
        <Switch.Description as="span" className={`text-sm font-medium leading-6 text-gray-500 ${textClassName}`}>
          {description}
        </Switch.Description>
      </span>
      <Switch
        disabled={disabled}
        checked={value}
        onChange={onChange}
        className={classNames(
          value ? "bg-primary-600" : "bg-gray-200",
          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2"
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            value ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
          )}
        />
      </Switch>
    </Switch.Group>
  );
};

export default Toogle;
