import dayjs from "dayjs";
import { action, makeAutoObservable, observable } from "mobx";
import { toast } from "react-toastify";
import { ReservationDto, STATUS } from "../dto/Reservation.dto";
import Axios from "../util/Axios";

export class ReservationStore {
  @observable reservations: ReservationDto[] = [];
  @observable reservationsStats: Record<
    string,
    { count: number; totalPersons: number }
  > = {};
  @observable todayReservations: ReservationDto[] = [];
  @observable allReservations: ReservationDto[] = [];
  @observable openReservationCount: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  async getAllTodayReservations(filterValue?: string) {
    const startOfDay = dayjs().startOf("day").format("YYYY-MM-DD");
    const endOfDay = dayjs().add(1, "day").startOf("day").format("YYYY-MM-DD");
    let url;

    if (filterValue) {
      const filter = this.createFilter(filterValue);
      url = `/reservations?filter=${JSON.stringify(
        filter
      )}&dateTime>=${startOfDay}&dateTime<${endOfDay}&status=ACCEPTED&sort=dateTime`;
    } else {
      url = `/reservations?dateTime>=${startOfDay}&dateTime<${endOfDay}&status=ACCEPTED&sort=dateTime`;
    }

    const response = await Axios.get(url);
    if (response.status === 200) {
      this.todayReservations = response.data;
    }
  }

  @action
  async getAllOpen() {
    const response = await Axios.get(
      `/reservations?status=OPEN&dateTime>=${dayjs().toISOString()}&sort=-dateTime`
    );

    if (response.status === 200) {
      this.reservations = response.data;
      this.openReservationCount = response.data.length;
    }
  }

  @action
  async getReservationsStats(dates: string[]) {
    const response = await Axios.get(
      `/reservations/stats?${this.getDatesQuery(dates)}`
    );

    if (response.status === 200) {
      this.reservationsStats = response.data[0];
    }
  }

  private getDatesQuery(dates: string[]) {
    return dates.map((date) => `date=${date}`).join("&");
  }

  @action
  async getAllReservations(
    filterValue?: string,
    startDate?: Date,
    endDate?: Date
  ) {
    const { startOfDay, endOfDay } = this.getDateRange(startDate, endDate);
    const queryString = this.buildQueryString(
      startOfDay,
      endOfDay,
      filterValue
    );
    const response = await Axios.get(queryString);

    if (response.status === 200) {
      this.allReservations = response.data;
    }
  }

  private getDateRange(startDate?: Date, endDate?: Date) {
    let startOfDay;
    let endOfDay;

    if (startDate && endDate) {
      startOfDay = dayjs(startDate).startOf("day").format("YYYY-MM-DD");
      endOfDay = dayjs(endDate).add(1, "day").startOf("day").format("YYYY-MM-DD");
    } else {
      startOfDay =  dayjs().startOf("week").format("YYYY-MM-DD");
      endOfDay =  dayjs().add(1, "day").endOf("week").format("YYYY-MM-DD");
    }

    return { startOfDay, endOfDay };
  }

  private buildQueryString(
    startOfDay: string,
    endOfDay: string,
    filterValue?: string
  ) {
    let queryString = `/reservations?dateTime>=${startOfDay}&dateTime<${endOfDay}&sort=dateTime&status=ACCEPTED&status=ARRIVED&status=NOT_ARRIVED`;

    if (filterValue) {
      const filter = this.createFilter(filterValue);
      queryString = `/reservations?filter=${JSON.stringify(
        filter
      )}&dateTime>=${startOfDay}&dateTime<${endOfDay}&sort=dateTime&status=ACCEPTED&status=ARRIVED&status=NOT_ARRIVED`;
    }

    return queryString;
  }

  @action
  async acceptReservation(id: string, limitTime?: boolean, acceptNote?: string) {
    const response = await Axios.put(`/reservations/${id}`, {
      status: "ACCEPTED",
      limitTime,
      acceptNote,
    });
    if (response.status === 200) {
      toast.success("Reservation accepted");
      this.getAllOpen();
      this.getAllReservations();
    }
  }

  @action
  async rejectReservation(id: string, reason?: string) {
    const response = await Axios.put(`/reservations/${id}`, {
      status: "DECLINED",
      declineReason: reason,
    });
    if (response.status === 200) {
      toast.success("Reservation rejected");
      this.getAllOpen();
      this.getAllReservations();
    }
  }

  @action
  async declineReservation(id: string) {
    const response = await Axios.put(`/reservations/${id}`, {
      status: "DECLINED",
    });
    if (response.status === 200) {
      toast.success("Reservation abgelehnt");
      this.getAllOpen();
      this.getAllReservations();
    }
  }

  @action
  async updateReservation(id: string, reservation: ReservationDto) {
    const response = await Axios.put(`/reservations/${id}`, reservation);
    if (response.status === 200) {
      toast.success("Reservation updated");
      this.getAllOpen();
      this.getAllTodayReservations();
      this.getAllReservations();
    }
  }

  @action
  async createReservation(reservation: ReservationDto) {
    const response = await Axios.post("/reservations", reservation);
    if (response.status === 201) {
      toast.success("Reservation created");
      this.getAllOpen();
      this.getAllReservations();
    }
  }

  @action
  async setArrived(id: string, arrived: boolean) {
    const response = await Axios.put(`/reservations/${id}`, {
      status: arrived ? STATUS.ARRIVED : STATUS.NOT_ARRIVED,
    });

    if (response.status === 200) {
      toast.success("Reservation updated");
      this.getAllOpen();
      this.getAllReservations();
    }
  }

  private createFilter(filterValue: string) {
    const filter = {
      $or: [
        { firstName: { $regex: filterValue } },
        { lastName: { $regex: filterValue } },
        { email: { $regex: filterValue } },
      ],
    };
    return filter;
  }
}

const reservationStore = new ReservationStore();
export default reservationStore;
