import React, { useEffect, useState } from "react";
/* This example requires Tailwind CSS v2.0+ */

import Layout from "../../components/Layout/Layout";
import { observer } from "mobx-react";
import Table from "../../components/Table/Table";
import reservationStore from "../../stores/reservation.store";
import Loading from "../../components/Loading/Loading";
import PageHeader from "../../components/PageHeader/PageHeader";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const ReservationNew = () => {
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    loadReservations();
  }, []);

  const loadReservations = async () => {
    setLoading(true);
    await reservationStore.getAllOpen();

    const dates = reservationStore.reservations.map((r) => dayjs(r.dateTime).format("YYYY-MM-DD"));
    await reservationStore.getReservationsStats(dates)
    setLoading(false);
  };



  return (
    <Layout>
      {loading && <Loading />}

      <PageHeader title={t("reservation.titleOpen")} disableButton />
      <Table
        reservations={reservationStore.reservations}
        reservationsStats={reservationStore.reservationsStats}
        emptyTitle={"reservation.emptyTitleOpen"}
        emptySubtitle={"reservation.emptySubtitleOpen"}
      />
    </Layout>
  );
};

export default observer(ReservationNew);
