import React from "react";
import { useTranslation } from "react-i18next";

interface SecondaryButtonProps {
  title: string;
  onClick: () => void;
}

const SecondaryButton = ({ title, onClick }: SecondaryButtonProps) => {
  const { t } = useTranslation();

  return (
    <button
      onClick={onClick}
      type="button"
      className="px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
    >
      {t(title)}
    </button>
  );
};

export default SecondaryButton;
