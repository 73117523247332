import { XIcon } from "@heroicons/react/outline";
import { inject } from "mobx-react";
import React from "react";
import { ModalStore } from "../../stores/modal.store";

interface Props {
  title: string;
  modalStore?: ModalStore;
  className?: string;
  onClose?: () => void;
}

const CardHeading = ({ title, modalStore, className, onClose }: Props) => {
  return (
    <div className={`border-b border-gray-200 bg-white pb-5 ${className}`}>
      <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {title}
          </h3>
        </div>
        <div className="flex flex-shrink-0 flex-row items-center">
          <div
            className="ml-3 cursor-pointer"
            onClick={() => {
              if (onClose) {
                onClose();
              }
              modalStore?.setModal("");
            }}
          >
            <XIcon className="w-8 h-8" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default inject("modalStore")(CardHeading);
