import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import TextField from "../../components/TextField/TextField";

const Installation = () => {
  const { register } = useForm();
  const { t } = useTranslation();
  return (
    <div>
      {" "}
      <h3 className="font-bold">Installation</h3>
      <div>
        Das ist die Anleitung wie du das in deine Seite einbindest bli bla blub
      </div>
      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5">
        <label
          htmlFor="first-name"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          {t("createShift.apiKey")}
        </label>
        <div className="mt-1 sm:col-span-2 sm:mt-0">
          <TextField
            register={register("apiKey", {})}
            disabled
            placeholder="Api-Key"
            title=""
            hideTitle
          />
        </div>
      </div>
    </div>
  );
};

export default Installation;
