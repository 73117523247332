import React from "react";
import "./App.css";
import { Provider } from "mobx-react";
import { stores } from "./stores";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateShiftModal from "./components/CreateShiftModal/CreateShiftModal";
import AppRouter from "./Routing/AppRouter";
import CreateVacationModal from "./components/CreateVacationModal/CreateVacationModal";
import ReservationModal from "./components/ReservationModal/ReservationModal";

function App() {
  return (
    <Provider {...stores}>
      <AppRouter />
      <ToastContainer />
      <CreateShiftModal />
      <CreateVacationModal />
      <ReservationModal />
    </Provider>
  );
}

export default App;
