import React from "react";
import { useTranslation } from "react-i18next";

interface PrimaryButtonProps {
  title: string;
  onClick?: () => void;
  className?: any;
  submit?: boolean;
}

const PrimaryButton = ({
  title,
  onClick,
  className,
  submit,
}: PrimaryButtonProps) => {
  const { t } = useTranslation();

  if (submit) {
    return (
      <button
        type="submit"
        className={`inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 rounded-md shadow-sm text-white bg-primary-500 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 font-bold justify-center ${className}`}
      >
        {t(title)}
      </button>
    );
  }
  return (
    <button
      onClick={onClick}
      type="button"
      className={`inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-primary-500 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 justify-center ${className}`}
    >
      {t(title)}
    </button>
  );
};

export default PrimaryButton;
