import Axios from "../util/Axios";
import { action, makeAutoObservable, observable } from "mobx";
import { toast } from "react-toastify";
import { ShiftDto } from "../dto/Shift.dto";

export class ShiftStore {
  @observable shifts: ShiftDto[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  @action
  async loadShifts() {
    const response = await Axios.get("/shifts");
    if (response.status === 200) {
      this.shifts = response.data;
    }
  }

  @action
  async createShift(data: ShiftDto) {
    try {
      const response = await Axios.post("/shifts", data);
      this.showResponseMessage(
        response.status,
        "Schicht erstellt",
        "Schicht konnte nicht erstellt werden"
      );
      return response.status === 201;
    } catch (err) {
      toast.error("Schicht konnte nicht erstellt werden");
      return false;
    }
  }

  @action
  async updateShift(id: string, data: ShiftDto) {
    try {
      const response = await Axios.put(`/shifts/${id}`, data);
      this.showResponseMessage(
        response.status,
        "Schicht wurde erfolgreich gespeichert",
        "Schicht konnte nicht gespeichert werden"
      );
      return response.status === 200;
    } catch (err) {
      toast.error("Schicht konnte nicht gespeichert werden");
      return false;
    }
  }

  private showResponseMessage(
    status: number,
    successMessage: string,
    errorMessage: string
  ) {
    if (status === 201 || status === 200) {
      toast.success(successMessage);
      this.loadShifts();
    } else {
      toast.error(errorMessage);
    }
  }
}

export const prepareData = (data: any): ShiftDto => {
  let dayOfWeekResult = [];
  for (let dayKey of Object.keys(data.daysOfWeek)) {
    if (data.daysOfWeek[dayKey]) {
      dayOfWeekResult.push(dayKey);
    }
  }

  const firstSeating = data.firstSeating?.split(":");
  const lastSeating = data.lastSeating?.split(":");
  let sameDayReservationUntil = undefined;

  if (data.sameDayReservation?.enabled && data.sameDayReservation?.until) {
    sameDayReservationUntil = data.sameDayReservation?.until?.split(":");
  }

  return {
    ...data,
    daysOfWeek: dayOfWeekResult,
    firstSeating: {
      hours: parseInt(firstSeating[0]),
      minutes: parseInt(firstSeating[1]),
    },
    lastSeating: {
      hours: parseInt(lastSeating[0]),
      minutes: parseInt(lastSeating[1]),
    },
    sameDayReservation: {
      enabled: data.sameDayReservation.enabled,
      until: sameDayReservationUntil
        ? {
            hours: parseInt(sameDayReservationUntil[0]),
            minutes: parseInt(sameDayReservationUntil[1]),
          }
        : undefined
    },
    enabled: true,
  } as ShiftDto;
};

const shiftStore = new ShiftStore();
export default shiftStore;
