import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
import TextArea from "../TextArea/TextArea";
import { Controller, useForm } from "react-hook-form";
import { t } from "i18next";
import Toogle from "../Toogle/Toogle";

interface Props {
  title: string;
  description: string;
  cancelText: string;
  confirmText: string;
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  onConfirm: (text?: string) => void;
  onCancel: () => void;
  enableText?: boolean;
  placeholder?: string;
  enableTimeLimit?: boolean;
}

export default function Confirm({
  title,
  description,
  cancelText,
  confirmText,
  isOpen,
  setOpen,
  onConfirm,
  onCancel,
  enableText,
  placeholder,
  enableTimeLimit,
}: Props) {
  const cancelButtonRef = useRef(null);
  const { handleSubmit, register, control, watch, setValue } = useForm();

  const onSubmit = (data: any) => {
    onConfirm(data);
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <form className="space-y-8" onSubmit={handleSubmit(onSubmit)}>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                      <ExclamationIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        {title}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">{description}</p>
                      </div>
                      {enableTimeLimit && (
                        <div className="mt-5 border-t pt-5">
                          <Controller
                            control={control}
                            name={`limitReservation`}
                            render={({ field: { ref, ...field } }) => (
                              <Toogle
                                textClassName="text-left"
                                disabled={false}
                                title={t("reservationSettings.durationTitle")}
                                description={t(
                                  "reservationSettings.durationLimit"
                                )}
                                {...field}
                                value={watch("limitReservation")}
                                onChange={(checked: boolean) => {
                                  field.onChange(checked);
                                  if (!checked) {
                                    setValue("limitReservation", "");
                                  }
                                }}
                              />
                            )}
                          />
                        </div>
                      )}
                      {enableText && (
                        <div className="mt-6">
                          <TextArea
                            register={register("text", { required: false })}
                            placeholder={placeholder ? placeholder : ""}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 sm:col-start-2"
                    >
                      {confirmText}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => onCancel()}
                    >
                      {cancelText}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </form>
      </Dialog>
    </Transition.Root>
  );
}
