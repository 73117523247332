import React from "react";

interface PageHeaderProps {
  title: string;
  disableButton?: boolean;
  buttonName?: string;
  onClick?: () => void;
}

const PageHeader = ({
  title,
  buttonName,
  onClick,
  disableButton = false,
}: PageHeaderProps) => {
  return (
    <div className="flex items-center justify-between px-8 py-6">
      <div className="flex-1 min-w-0 flex items-center">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:tracking-tight sm:truncate">
          {title}
        </h2>
      </div>
      {!disableButton && (
        <button
          onClick={onClick}
          type="button"
          className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          {buttonName}
        </button>
      )}
    </div>
  );
};

export default PageHeader;
