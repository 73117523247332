import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { ShiftStore } from "../../stores/shift.store";
import dayjs from "dayjs";
import Loading from "../Loading/Loading";
import { useTranslation } from "react-i18next";

interface Props {
  shiftStore?: ShiftStore;
  openDetail: (item: any) => void;
}

const ShiftTable = ({ shiftStore, openDetail }: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [headers] = useState<string[]>([
    "name",
    "daysOfWeek",
    "startDate",
    "endDate",
    "enabled",
  ]);

  useEffect(() => {
    const load = async () => {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
      setLoading(true);
      await shiftStore?.loadShifts();
      setLoading(false);
    };
    load();
  }, [shiftStore]);

  const open = (item: any) => {
    openDetail(item);
  };

  const renderHeader = () => {
    return headers.map((item, key) => (
      <th
        key={item + key}
        scope="col"
        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
      >
        {item !== "" && t("shiftSettings." + item)}
      </th>
    ));
  };

  const renderRows = () => {
    const renderValue = (value: any) => {
      if (Array.isArray(value)) {
        return value
          .map((item: string) => t("weekdays.short." + item))
          .join(", ");
      }
      if (typeof value == "boolean") {
        return (
          <span
            className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium  ${
              value ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"
            } `}
          >
            {value ? "Aktiv" : "Deaktiviert"}
          </span>
        );
      }
      if (value && dayjs(value).isValid()) {
        return dayjs(value).format("DD.MM.YYYY");
      }
      return value;
    };

    return shiftStore?.shifts.map((entry: any, key) => {
      return (
        <tr
          key={entry._id}
          className="hover:bg-slate-100 cursor-pointer"
          onClick={() => open(entry)}
        >
          {headers.map((item: string, keyItem: number) => (
            <td
              className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
              key={entry._id + item}
            >
              {renderValue(entry[item])}
            </td>
          ))}
          <td
            onClick={() => open(entry)}
            className="cursor-pointer whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-primary-600 hover:text-primary-900"
          >
            {t("shiftSettings.details")}
          </td>
        </tr>
      );
    });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col w-full">
      <div className="inline-block min-w-full align-middle">
        <div className="overflow-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                {renderHeader()}
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                ></th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {renderRows()}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default inject("shiftStore")(observer(ShiftTable));
