import { inject } from "mobx-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { MODAL_NAME_CREATE_SHIFT } from "../../../components/CreateShiftModal/CreateShiftModal";
import Layout from "../../../components/Layout/Layout";
import PageHeader from "../../../components/PageHeader/PageHeader";
import ShiftDetailSlideOver from "../../../components/ShiftDetailSlideOver/ShiftDetailSlideOver";
import ShiftTable from "../../../components/ShiftTabel/ShiftTable";
import { ModalStore } from "../../../stores/modal.store";

interface Props {
  modalStore?: ModalStore;
}

const ShiftSettings = ({ modalStore }: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState(null);
  return (
    <Layout>
      <div className="mt-16 lg:mt-0 ">
        <PageHeader
          title="Shift Settings"
          onClick={() => {
            modalStore?.setModal(MODAL_NAME_CREATE_SHIFT);
          }}
          buttonName={t("shiftSettings.create")}
        />
        <div className="m-8 mt-4">
          <ShiftTable
            openDetail={(item) => {
              setItem(item);
              setOpen(true);
            }}
          />
          <ShiftDetailSlideOver
            open={open}
            onClose={() => setOpen(false)}
            item={item}
          />
        </div>
      </div>
    </Layout>
  );
};

export default inject("modalStore")(ShiftSettings);
