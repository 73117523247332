import React, { useEffect, useState } from "react";
import { PencilAltIcon } from "@heroicons/react/solid";
import Layout from "../../components/Layout/Layout";
import { observer } from "mobx-react";
import reservationStore from "../../stores/reservation.store";
import Loading from "../../components/Loading/Loading";
import PageHeader from "../../components/PageHeader/PageHeader";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import ReservationDayItem from "../../components/ReserverationDayItem/ReservationDayItem";
import modalStore from "../../stores/modal.store";
import { MODAL_NAME_RESERVATION } from "../../components/ReservationModal/ReservationModal";
import SearchInput from "../../components/SearchInput/SearchInput";
import EmptyState from "../../components/EmptyState/EmptyState";
import DateRangePicker from "../../components/DateRangePicker/DateRangePicker";

const Reservation = () => {
  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const [groupedData, setGroupedData] = useState<any>([]);
  const defaultStartDate = dayjs().startOf("week").toDate();
  const defaultEndDate = dayjs().endOf("week").toDate();
  const [startDate, setStartDate] = useState<Date>(
    defaultStartDate
  );
  const [endDate, setEndDate] = useState<Date>(defaultEndDate);

  const loadReservations = async (
    filter?: string,
    startDate?: Date,
    endDate?: Date
  ) => {
    setLoading(true);
    await reservationStore.getAllReservations(filter, startDate, endDate);
    setLoading(false);
  };

  useEffect(() => {
    loadReservations(search, startDate, endDate);
  }, [search, startDate, endDate]);

  useEffect(() => {
    if (reservationStore.allReservations) {
      const data: any = {};
      for (const reservation of reservationStore.allReservations) {
        const time = dayjs(reservation.dateTime).format("DD.MM.YYYY");

        if (!data[time]) {
          data[time] = [];
        }
        data[time].push(reservation);
      }
      setGroupedData(data);
    }
  }, [reservationStore.allReservations]);

  const handleDateRangeChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <Layout>
      <PageHeader title={`${t("reservation.allReservations")}`} disableButton />
      <div className="p-6">
        <div className="flex flex-col justify-center">
          <div className="mb-6 flex justify-center items-center">
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onDateChange={handleDateRangeChange}
              onChevronClicked={handleDateRangeChange}
            />
          </div>
          <SearchInput setSearch={setSearch} />
        </div>
      </div>
      <div className="p-6">
        {loading && <Loading center={false} />}
        {Object.keys(groupedData).length === 0 ? (
          <div className="mt-5">
            <EmptyState
              title={"reservation.emptyTitle"}
              subtitle={"reservation.emptySubtitle"}
            />
          </div>
        ) : (
          ""
        )}
        {Object.keys(groupedData).map((key: string) => {
          return (
            <div key={key} className="mb-8">
              <ReservationDayItem
                title={key}
                reservations={groupedData[key]}
                renderRow={(reservation, index) => {
                  return (
                    <div
                      key={reservation._id}
                      className={`border-b lg:flex-nowrap flex-wrap lg:flex-none flex flex-col lg:flex-row  pb-3 lg:pb-0 ${
                        index % 2 === 0 ? "bg-gray-50" : "bg-white"
                      }`}
                    >
                      <div className="whitespace-nowrap text-sm font-medium text-gray-900 p-3 w-1/6">
                        {`${dayjs(reservation.dateTime).format("HH:mm")}`}
                      </div>
                      <div className="whitespace-nowrap text-sm font-medium text-gray-900 p-3 w-1/6">
                        {reservation.numberOfPersons === 1
                          ? `${
                              reservation.numberOfPersons
                                ? reservation.numberOfPersons
                                : 0
                            } ${t("person")}`
                          : `${
                              reservation.numberOfPersons
                                ? reservation.numberOfPersons
                                : 0
                            } ${t("persons")}`}
                      </div>
                      <div className="whitespace-nowrap text-sm font-medium text-gray-900 p-3 w-1/6">
                        {`${reservation.firstName} ${reservation.lastName}`}
                      </div>
                      <div className="whitespace-nowrap text-sm font-medium text-gray-900  p-3 w-1/6">
                        <a
                          href={`mailto:${reservation.email}`}
                          className="underline"
                        >
                          {reservation.email}
                        </a>
                      </div>
                      <div className="whitespace-nowrap text-sm font-medium text-gray-900  p-3 w-1/6">
                        <a
                          href={`tel:${reservation.phoneNumber}`}
                          className="underline"
                        >
                          {reservation.phoneNumber}
                        </a>
                      </div>
                      {/* <td className="whitespace-nowrap text-sm font-medium text-gray-900  p-3 overflow-hidden text-ellipsis">
                        {reservation.note?.slice(0, 90)}
                      </td> */}
                      <div className="whitespace-nowrap text-sm font-medium text-gray-900  p-3  overflow-hidden text-ellipsis w-1/6">
                        {t(reservation.status)}
                      </div>
                      <div
                        className="cursor-pointer flex justify-center items-center mr-3"
                        onClick={() => {
                          modalStore.setModal(MODAL_NAME_RESERVATION, {
                            isEdit: true,
                            selected: reservation,
                          });
                        }}
                      >
                        <PencilAltIcon className="h-5 w-5 text-gray-500" />
                      </div>
                    </div>
                  );
                }}
              />
            </div>
          );
        })}
      </div>
      <div className="h-64"></div>
    </Layout>
  );
};

export default observer(Reservation);
