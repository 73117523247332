import axios from "axios";
const API_URL = process.env.REACT_APP_API;

const baseURL = API_URL;
const instance = axios.create({
  baseURL: baseURL,
  timeout: 10000,
});

instance.interceptors.request.use((config) => {
  return new Promise(async (resolve, reject) => {
    let token = await localStorage.getItem("essen24_jwt");
    const restaurant = window.location.pathname.split("/")[1];

    if (token && config.headers) {
      config.headers.Authorization = "Bearer " + token;
      config.headers.restaurant = restaurant;
    }
    resolve(config);
  });
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (!error.response) {
      return Promise.reject(error);
    }
    return refreshToken(error);
  }
);

const refreshToken = async (error: any) => {
  const originalRequest = error.config;

  if (
    error.response.status === 401 &&
    originalRequest.url === "/auth/refresh"
  ) {
    return Promise.reject(error);
  }
  error.response.status = 401;
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    const refreshToken = await localStorage.getItem("essen24_refreshToken");
    const resp = await axios.post(baseURL + "/auth/refresh-access-token", {
      refreshToken,
    });

    if (resp.status === 201) {
      const token = resp.data.accessToken;

      await localStorage.setItem("essen24_jwt", resp.data.accessToken);
      if (token && instance.defaults && instance.defaults.headers) {
        //@ts-ignore
        instance.defaults.headers.common["Authorization"] = "Bearer " + token;
      }
      return instance(originalRequest);
    }
  }
  return Promise.reject(error);
};

export default instance;
