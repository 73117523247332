import React from "react";
import RDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.css";

import { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import { Controller } from "react-hook-form";
registerLocale("de", de);

interface Props {
  fieldName: string;
  rules: any;
  control: any;
  disabled?: boolean;
  defaultValue?: Date;
  startDate?: Date;
  filterDate?: (date: Date) => boolean;
}

const DatePicker = ({
  control,
  rules,
  fieldName,
  disabled,
  defaultValue,
  startDate,
  filterDate,
}: Props) => {
  return (
    <Controller
      name={fieldName}
      defaultValue={defaultValue}
      control={control}
      rules={rules}
      render={({ field }) => (
        <RDatePicker
          minDate={startDate}
          disabled={disabled}
          className="cursor-pointer w-full relative border border-gray-300 focus:outline-none rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-primary-600 focus-within:border-primary-600 sm:text-sm text-gray-900 placeholder-gray-500"
          selected={field.value}
          locale="de"
          dateFormat={["dd.MM.yyyy", "dd.M.yy"]}
          filterDate={(date) => {
            if (filterDate) {
              return filterDate(date);
            }
            return true;
          }}
          {...field}
        />
      )}
    />
  );
};

export default DatePicker;
