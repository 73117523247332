import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface Props {
  control: any;
  fieldName: string;
  rules?: any;
  disabled?: boolean;
}

const DayPicker = ({ fieldName, control, rules, disabled }: Props) => {
  const { t } = useTranslation();
  const daysOfWeek: any = {
    MONDAY: false,
    TUESDAY: false,
    WEDNESDAY: false,
    THURSDAY: false,
    FRIDAY: false,
    SATURDAY: false,
    SUNDAY: false,
  };

  const renderButtons = (values: any, onChange: any) => {
    return Object.keys(values).map((itemKey: string, key: number) => {
      const firstBorderStyle = "rounded-l-md ";
      const lastBorderStyle = "rounded-r-md ";

      const active =
        "cursor-pointer relative inline-flex items-center border border-primary-300 bg-primary-700 px-4 py-2 text-sm font-medium text-white  focus:z-10 focus:border-primary-500 focus:outline-none focus:ring-1";
      const inactive =
        "cursor-pointer relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500";

      return (
        <div
          className={` ${values[itemKey] ? active : inactive} ${
            key === 0 ? firstBorderStyle : ""
          }
          ${key === Object.keys(values).length - 1 ? lastBorderStyle : ""}`}
          onClick={() => {
            if (!disabled) {
              const tmp = values;
              tmp[itemKey] = !tmp[itemKey];
              onChange({ ...tmp });
            }
          }}
        >
           {itemKey !== "" && t("weekdays.long." + itemKey)}
        </div>
      );
    });
  };

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={rules}
      defaultValue={daysOfWeek}
      render={({ field: { onChange, onBlur, value, ref } }) => {
        return (
          <span className="relative z-0 inline-flex rounded-md shadow-sm flex-wrap">
            {renderButtons(value, onChange)}
          </span>
        );
      }}
    />
  );
};

export default DayPicker;
