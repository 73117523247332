import React from "react";

interface Props {
  text: string;
}

const Information = ({ text }: Props) => {
  return <div className="p-2 bg-yellow-100 rounded-md text-sm">{text}</div>;
};

export default Information;
