import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { ModalStore } from "../../stores/modal.store";
import DatePicker from "../DatePicker/DatePicker";
import CardHeading from "../ModalCard/CardHeading";
import ModalCard from "../ModalCard/ModalCard";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import { useForm } from "react-hook-form";
import Information from "../Information/Information";
import Confirm from "../Confirm/Confirm";
import TextField from "../TextField/TextField";
import dayjs from "dayjs";
import reservationStore from "../../stores/reservation.store";
import DropDown from "../DropDown/DropDown";
import TextArea from "../TextArea/TextArea";
import restaurantStore from "../../stores/restaurant.store";
import { hasDateTimeChanged } from "../../util/utils";

export const MODAL_NAME_RESERVATION = "ReservationModal";

interface ReservationModalProps {
  modalStore?: ModalStore;
}

const getConfirmationTexts = (
  t: TFunction,
  isCreation: boolean,
  isEditReservationsOpen: boolean
) => {
  if (isEditReservationsOpen) {
    return {
      title: t("reservation.confirmTitle"),
      description: t("reservation.confirmDescription"),
      confirmText: t("reservation.confirmText"),
      cancelText: t("reservation.cancelText"),
    };
  } else if (isCreation) {
    return {
      title: t("reservation.confirmCreateTitle"),
      description: t("reservation.confirmCreateDescription"),
      confirmText: t("reservation.confirmCreateText"),
      cancelText: t("reservation.cancelText"),
    };
  } else {
    return {
      title: t("reservation.confirmUpdateTitle"),
      description: t("reservation.confirmUpdateDescription"),
      confirmText: t("reservation.confirmUpdateText"),
      cancelText: t("reservation.cancelText"),
    };
  }
};

const ReservationModal = ({ modalStore }: ReservationModalProps) => {
  const isCreate = modalStore?.customData?.isCreate;
  const isEditReservationsOpen = modalStore?.customData?.isEditReservationsOpen;
  const isDeclined = modalStore?.customData?.selected?.status === "DECLINED";
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const { t } = useTranslation();
  const { handleSubmit, control, reset, register, getValues } = useForm({
    defaultValues: {
      numberOfPersons: undefined,
      firstName: undefined,
      lastName: undefined,
      phoneNumber: undefined,
      email: undefined,
      note: undefined,
      duration: 120,
      time: undefined,
      date: undefined,
      status: undefined,
      limitReservation: false,
    },
  });
  const { title, description, confirmText, cancelText } = getConfirmationTexts(
    t,
    isCreate,
    isEditReservationsOpen
  );

  useEffect(() => {
    if (modalStore?.customData?.selected) {
      const { selected } = modalStore?.customData;
      selected.date = new Date(selected.dateTime);
      selected.time = dayjs(selected.dateTime).format("HH:mm");

      if (isEditReservationsOpen) {
        reset({ ...selected, status: "ACCEPTED" });
      } else {
        reset(selected);
      }
    } else {
      console.log("reset");
      reset({
        numberOfPersons: undefined,
        firstName: undefined,
        lastName: undefined,
        phoneNumber: undefined,
        email: undefined,
        note: undefined,
        duration: 120,
        time: undefined,
        date: undefined,
        status: undefined,
        limitReservation: false,
      });
    }
  }, [modalStore?.customData, reset]);

  if (modalStore?.currentModal !== MODAL_NAME_RESERVATION) {
    return <></>;
  }

  const onSubmit = async (data: any) => {
    setIsOpenUpdate(true);
  };

  const generateTitle = () => {
    if (isDeclined) {
      return t("reservation.declineTitle");
    }
    return isCreate
      ? t("reservation.createTitle")
      : t("reservation.updateTitle");
  };

  return (
    <ModalCard isOpen={modalStore?.currentModal === MODAL_NAME_RESERVATION}>
      <div className="w-full h-full flex justify-end  absolute top-0 left-0 right-0 bottom-0">
        <div className="sm:w-full md:w-full lg:w-1/3 bg-white min-h-screen p-5 shadow-md overflow-auto">
          <CardHeading
            title={generateTitle()}
            className="mb-5"
            onClose={() => {
              reset();
            }}
          />
          {isCreate ? <Information text={t("reservation.help")} /> : ""}
          <form className="space-y-8" onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-8  sm:space-y-5">
              <div className="space-y-6 sm:space-y-5">
                {modalStore?.customData?.selected &&
                  modalStore?.customData?.selected.duration === 0 && (
                    <Information
                      text={"Diese Reservierung wurde verlängert."}
                    />
                  )}
                <div className="col-span-6 sm:col-span-3 mt-5">
                  <label className="block text-sm font-medium text-gray-700">
                    {t("reservation.numberOfPersons")}
                  </label>
                  <TextField
                    register={register("numberOfPersons", {
                      required: true,
                    })}
                    placeholder={t("reservation.numberOfPersons")}
                    title=""
                    hideTitle
                  />
                </div>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label className="block text-sm font-medium text-gray-700">
                      {t("reservation.firstName")}
                    </label>
                    <TextField
                      register={register("firstName", {
                        required: true,
                      })}
                      placeholder={t("reservation.firstName")}
                      title=""
                      hideTitle
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label className="block text-sm font-medium text-gray-700">
                      {t("reservation.lastName")}
                    </label>
                    <TextField
                      register={register("lastName", {
                        required: true,
                      })}
                      placeholder={t("reservation.lastName")}
                      title=""
                      hideTitle
                    />
                  </div>
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label className="block text-sm font-medium text-gray-700">
                    {t("reservation.phoneNumber")}
                  </label>
                  <TextField
                    register={register("phoneNumber", {
                      required: true,
                    })}
                    placeholder={t("reservation.phoneNumber")}
                    title=""
                    hideTitle
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label className="block text-sm font-medium text-gray-700">
                    {t("reservation.email")}
                  </label>
                  <TextField
                    register={register("email", {
                      required: false,
                    })}
                    placeholder={t("reservation.email")}
                    title=""
                    hideTitle
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label className="block text-sm font-medium text-gray-700">
                    {t("reservation.note")}
                  </label>
                  <TextArea
                    register={register("note")}
                    placeholder={t("reservation.note")}
                    title=""
                    hideTitle
                  />
                </div>
                {/*                 {restaurantStore?.currentRestaurant?.enableReservationLimit && (
                  <div className="col-span-6 sm:col-span-3">
                    <Controller
                      control={control}
                      name={`limitReservation`}
                      render={({ field: { ref, ...field } }) => (
                        <Toogle
                          textClassName="text-left"
                          disabled={false}
                          title={t("reservationSettings.durationTitle")}
                          description={t("reservationSettings.durationLimit")}
                          {...field}
                          value={watch("limitReservation")}
                          onChange={(checked: boolean) => {
                            field.onChange(checked);
                            if (!checked) {
                              setValue("limitReservation", "");
                            }
                          }}
                        />
                      )}
                    />
                  </div>
                )} */}
                <div className="col-span-6 sm:col-span-3">
                  <label className="block text-sm font-medium text-gray-700">
                    {t("reservation.duration")}
                  </label>
                  <TextField
                    register={register("duration", {
                      required: true,
                    })}
                    placeholder={t("reservation.duration")}
                    title=""
                    type="number"
                    hideTitle
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label className="block text-sm font-medium text-gray-700">
                    {t("reservation.time")}
                  </label>
                  <TextField
                    register={register("time", {
                      required: true,
                    })}
                    placeholder={t("reservation.time")}
                    title=""
                    type="time"
                    hideTitle
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label className="block text-sm font-medium text-gray-700">
                    {t("reservation.date")}
                  </label>
                  <DatePicker fieldName="date" rules={{}} control={control} />
                </div>
                {!isCreate && (
                  <div className="col-span-6 sm:col-span-3">
                    <label className="block text-sm font-medium text-gray-700">
                      {t("status")}
                    </label>
                    <DropDown
                      fieldName="status"
                      control={control}
                      options={[
                        { value: "OPEN", label: t("OPEN") },
                        { value: "ACCEPTED", label: t("ACCEPTED") },
                        { value: "DECLINED", label: t("DECLINED") },
                      ]}
                      rules={{}}
                    />
                  </div>
                )}
              </div>
            </div>
            {isCreate && (
              <div className="flex justify-end">
                <PrimaryButton
                  submit
                  title={t("reservation.save")}
                  className="relative inline-flex items-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 w-1/2"
                />
              </div>
            )}

            {!isCreate && !isEditReservationsOpen && (
              <div className="flex justify-end">
                <PrimaryButton
                  submit
                  title={t("reservation.update")}
                  className="relative inline-flex items-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 w-1/2"
                />
              </div>
            )}

            {isEditReservationsOpen && (
              <div className="flex justify-end">
                <PrimaryButton
                  submit
                  title={t("reservation.accept")}
                  className="relative inline-flex items-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 w-1/2"
                />
              </div>
            )}
          </form>
        </div>
      </div>
      <Confirm
        isOpen={isOpen}
        setOpen={() => {}}
        title={t("reservation.confirmCancelTitle")}
        description={t("reservation.confirmCancelDescription")}
        confirmText={t("reservation.confirmCancelText")}
        cancelText={t("reservation.cancelText")}
        onCancel={() => {
          setIsOpen(false);
          reset();
          modalStore?.setModal("");
        }}
        onConfirm={async () => {
          await reservationStore.declineReservation(
            modalStore?.customData?.selected?._id
          );
          modalStore?.setModal("");

          setIsOpen(false);
          reset();
          modalStore?.setModal("");
        }}
      />
      <Confirm
        enableTimeLimit={
          restaurantStore?.currentRestaurant?.enableReservationLimit
        }
        isOpen={isOpenUpdate}
        setOpen={() => {
          setIsOpen(false);
        }}
        title={title}
        description={description}
        confirmText={confirmText}
        cancelText={cancelText}
        onCancel={() => {
          setIsOpenUpdate(false);
          reset();
          modalStore?.setModal("");
        }}
        enableText={isEditReservationsOpen}
        onConfirm={async (data?: any) => {
          const values: any = getValues();
          const acceptNote = data ? data.text : "";
          const limitTime = values.limitReservation
            ? values.limitReservation
            : false;
          values.dateTime = dayjs(values.date)
            .set("hour", values.time.split(":")[0])
            .set("minute", values.time.split(":")[1])
            .toISOString();

          if (
            modalStore?.customData?.selected &&
            !isCreate &&
            !isEditReservationsOpen
          ) {
            await reservationStore?.updateReservation(
              modalStore?.customData?.selected._id,
              { ...values, isCreate, isEdit: !isCreate, limitTime }
            );
          } else if (isEditReservationsOpen) {
            //only send accept edit email if date or time has changed otherwise accept reservation email with updated data
            await reservationStore?.updateReservation(
              modalStore?.customData?.selected._id,
              {
                ...values,
                isEditReservationsOpen: hasDateTimeChanged(
                  modalStore?.customData?.selected.dateTime,
                  values.dateTime
                ),
                limitTime,
                acceptNote
              }
            );
          } else {
            await reservationStore?.createReservation({
              ...values,
              isCreate,
              isEdit: !isCreate,
              limitTime,
            });
          }

          setIsOpenUpdate(false);
          reset();
          modalStore?.setModal("");
        }}
      />
    </ModalCard>
  );
};

export default inject(
  "modalStore",
  "vacationStore"
)(observer(ReservationModal));
