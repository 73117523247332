import { inject } from "mobx-react";
import React from "react";
import { MODAL_NAME_CREATE_SHIFT } from "../../components/CreateShiftModal/CreateShiftModal";
import Layout from "../../components/Layout/Layout";
import PageHeader from "../../components/PageHeader/PageHeader";
import { ModalStore } from "../../stores/modal.store";

interface ShiftProps {
  modalStore?: ModalStore;
}

const Shift = ({ modalStore }: ShiftProps) => {
  return (
    <Layout>
      <PageHeader
        title="Schichten"
        onClick={() => {
          modalStore?.setModal(MODAL_NAME_CREATE_SHIFT);
        }}
        buttonName="Erstellen"
      />
    </Layout>
  );
};

export default inject("modalStore")(Shift);
