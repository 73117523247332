export const de = {
  person: "Person",
  persons: "Personen",
  dateTime: "Datum",
  firstName: "Vorname",
  lastName: "Nachname",
  numberOfPersons: "Anzahl Personen",
  email: "E-Mail",
  note: "Notiz",
  phoneNumber: "Telefonnummer",
  status: "Status",
  OPEN: "Offen",
  ACCEPTED: "Akzeptiert",
  DECLINED: "Abgelehnt",
  ARRIVED: "Erschienen",
  NOT_ARRIVED: "Nicht erschienen",
  login: {
    headline: "Willkommen",
    email: "E-Mail Adresse",
    emailPlaceholder: "account@essen24.at",
    password: "Passwort",
    passwordPlaceholder: "********",
    submit: "Anmelden",
  },
  navbar: {
    addReservation: "Reservierung hinzufügen",
  },
  shiftSettings: {
    subtitle:
      "Hier können Sie die Schichten verwalten inkl Details wie Dauer einer Reservierung und maximale Belegung.",
    tableHeadline: "Erstellte Schichten",
    name: "Name",
    daysOfWeek: "Wochentage",
    startDate: "Start",
    endDate: "Ende",
    enabled: "Status",
    details: "Details",
    create: "Erstellen",
  },
  createShift: {
    title: "Schicht erstellen",
    save: "Speichern",
    name: "Name*",
    timeDate: "Zeitraum *",
    timeDateDetails:
      "Bitte selektieren Sie die Tage und Uhrzeiten der Schicht.",
    capacity: "Kapaziät *",
    capacityDetails: "Max Sitzplätze",
    notes: "Notiz",
    notesPlaceholder: "Hier können Sie eine Anmerkung hinein schreiben.",
    min: "Gäste minimum",
    minPlaceholder: "Wie viele Personen müssen mindestens reservieren",
    max: "Gäste maximum",
    maxPlaceholder: "Ab wann müssen Kunden Sie persönlich kontaktieren",
    firstSeating: "Erste Platzreservierung möglich um *",
    firstSeatingPlaceholder: "",
    lastSeating: "Letzte Platzreservierung möglich um *",
    lastSeatingPlaceholder: "",
    validFrom: "Von *",
    validTo: "Bis",
    interval: "Reservierbarer Intervall (in Minuten) *",
    intervalDetails: "Intervall Uhrzeit",
    duration: "Dauer in Minuten *",
    durationDetails: "Dauer einer Reservierung",
  },
  general: {
    title: "Stammdaten bearbeiten",
    general: "Allgemein",
    address: "Adresse",
    street: "Straße",
    zip: "PLZ",
    city: "Ort",
    save: "Speichern",
    name: "Restaurant Name",
    email: "E-Mail",
    phoneNumber: "Telefonnummer",
    homepage: "Homepage",
  },
  reservation: {
    notificationStatus: {
      scheduled: "geplant",
      delivered: "zugestellt",
      invalid_email: "ungültige E-Mail",
      error: "nicht zugestellt",
      soft_bounce: "nicht zugestellt",
      hard_bounce: "nicht zugestellt",
    },
    acceptTextAreaPlaceholder: "Nachricht an den Gast (optional)",
    duration: "Dauer (in Minuten) *",
    reservation: "Reservierung",
    reservations: "Reservierungen",
    titleOpen: "Reservierungsanfragen",
    reservationAt: "Reservierungen am",
    allReservations: "Alle Reservierungen",
    accept: "Akzeptieren",
    reject: "Ablehnen",
    emptyTitle: "Keine Reservierungen vorhanden",
    emptySubtitle: "Sie haben keine Reservierungen",
    emptyTitleOpen: "Keine Anfragen vorhanden",
    emptySubtitleOpen: "Sie haben keine offenen Anfragen",
    createTitle: "Reservierung erstellen",
    save: "Erstellen",
    help: "Füge eine Reservierung hinzu, um einen Tisch zu reservieren.",
    time: "Uhrzeit *",
    numberOfPersons: "Anzahl Personen *",
    firstName: "Vorname *",
    lastName: "Nachname *",
    email: "E-Mail",
    phoneNumber: "Telefonnummer *",
    date: "Datum *",
    note: "Notiz",
    updateTitle: "Reservierung bearbeiten",
    update: "Update",
    delete: "Ablehnen",
    confirmCancelTitle: "Reservierung ablehnen",
    confirmCancelDescription:
      "Sind Sie sicher, dass Sie diese Reservierung ablehnen wollen?",
    confirmCancelText: "Ablehnen",
    cancelText: "Abbrechen",
    cancelUpdateText: "Abbrechen",
    confirmUpdateTitle: "Reservierung bearbeiten",
    confirmUpdateDescription:
      "Sind Sie sicher, dass Sie diese Reservierung bearbeiten wollen?",
    confirmUpdateText: "Bearbeiten",
    declineTitle: "Reservierung abgelehnt",
    confirmCreateTitle: "Reservierung erstellen",
    confirmCreateDescription:
      "Sind Sie sicher, dass Sie diese Reservierung erstellen wollen?",
    confirmCreateText: "Erstellen",
    cancelCreateText: "Abbrechen",
    statInfoReservationOne_one:
      "{{reservationCount}} Reservierung ({{totalPersons}} Gast)",
    statInfoReservationOne_other:
      "{{reservationCount}} Reservierung ({{totalPersons}} Gäste)",
    statInfoReservationMultiple_one:
      "{{reservationCount}} Reservierungen ({{totalPersons}} Gast)",
    statInfoReservationMultiple_other:
      "{{reservationCount}} Reservierungen ({{totalPersons}} Gäste)",
    statInfoNoReservations: "Keine Reservierungen",
    arrivedTitle: "Gäste erschienen",
    arrivedDescription: "Sind Sie sicher, dass die Gäste angekommen sind?",
    arrivedConfirm: "OK",
    arrivedCancel: "Abbrechen",
    notArrivedTitle: "Gäste nicht angekommen",
    notArrivedDescription:
      "Sind Sie sicher, dass die Gäste nicht erschienen sind?",
    notArrivedConfirm: "Nicht erschienen",
    notArrivedCancel: "Abbrechen",
    confirmTitle: "Bestätigen",
    confirmDescription:
      "Sind Sie sicher, dass Sie diese Reservierung bestätigen wollen?",
    confirmText: "Bestätigen",
    cancelTextAreaPlaceholder: "Grund (Optional)",
  },
  reservationToday: {
    emptyTitle: "Keine Reservierungen",
    emptySubtitle: "Sie haben heute keine Reservierungen",
  },
  vacation: {
    title: "Urlaub",
    subtitle:
      "Bitte tragen Sie hier Ihre geplanten Urlaube ein. ACHTUNG: Bereits vergebene Reservierungen werden nicht storniert!",
    buttonName: "Erstellen",
    from: "Von",
    to: "Bis",
    confirmTitle: "Wollen Sie den Urlaub wirklich löschen?",
    confirmDescription: "Bitte treffen Sie eine Auswahl",
    cancelCancel: "Abbrechen",
    confirmConfirm: "Urlaub löschen",
  },
  createVacation: {
    title: "Urlaub erstellen",
    save: "Speichern",
    from: "Von *",
    to: "Bis *",
    help: "Füge einen Urlaub hinzu, um Reservierungen in diesem Zeitraum zu blockieren.",
    emptyTitle: "Keine Urlaube",
    emptySubtitle: "Sie haben keine Urlaube eingetragen",
    confirmTitle: "Urlaub erstellen",
    confirmDescription:
      "Sind Sie sicher, dass Sie diesen Urlaub erstellen wollen?",
    confirmText: "Erstellen",
    cancelText: "Abbrechen",
  },
  weekdays: {
    long: {
      MONDAY: "Montag",
      TUESDAY: "Dienstag",
      WEDNESDAY: "Mittwoch",
      THURSDAY: "Donnerstag",
      FRIDAY: "Freitag",
      SATURDAY: "Samstag",
      SUNDAY: "Sonntag",
    },
    short: {
      MONDAY: "Mo",
      TUESDAY: "Di",
      WEDNESDAY: "Mi",
      THURSDAY: "Do",
      FRIDAY: "Fr",
      SATURDAY: "Sa",
      SUNDAY: "So",
    },
  },
  reservationSettings: {
    title: "Reservierungseinstellungen",
    durationTitle: "Dauer einer Reservierung",
    durationDescription: "Soll dem Gast die begrenzte Reservierungszeit mitgeteilt werden?",
    durationLimit: "Soll dem Gast die begrenzte Reservierungszeit mitgeteilt werden?",
  },
};
