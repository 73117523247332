import { inject, observer } from "mobx-react";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Layout from "../../../components/Layout/Layout";
import PageHeader from "../../../components/PageHeader/PageHeader";
import PrimaryButton from "../../../components/PrimaryButton/PrimaryButton";
import TextField from "../../../components/TextField/TextField";
import { RestaurantStore } from "../../../stores/restaurant.store";
import Information from "../../../components/Information/Information";
import Toogle from "../../../components/Toogle/Toogle";

interface Props {
  restaurantStore?: RestaurantStore;
}

const ReservationSettings = ({ restaurantStore }: Props) => {
  const { t } = useTranslation();
  const { handleSubmit, watch, setValue, control } = useForm();

  useEffect(() => {
    const curr = restaurantStore?.currentRestaurant;
    if (!curr) {
      return;
    }

    for (let key of Object.keys(curr)) {
      setValue(key, curr[key]);
    }
  }, [restaurantStore?.currentRestaurant, setValue]);

  const onSubmit = (data: any) => {
    restaurantStore?.update(data);
  };

  return (
    <Layout>
      <div className="h-screen mt-16 lg:mt-0 ">
        <PageHeader title={t("reservationSettings.title")} disableButton />
        <form className="space-y-8  px-8 " onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-8  sm:space-y-5">
            <div className="space-y-6 sm:space-y-5  shadow rounded-md border p-3 bg-white">
              <Controller
                control={control}
                name={`enableReservationLimit`}
                render={({ field: { ref, ...field } }) => (
                  <Toogle
                    disabled={false}
                    title={t("reservationSettings.durationTitle")}
                    description={t("reservationSettings.durationDescription")}
                    {...field}
                    value={watch('enableReservationLimit')}
                 
                    onChange={(checked: boolean) => {
                      field.onChange(checked);
                    }}
                  />
                )}
              />
            </div>
          </div>

          <div className="flex justify-end">
            <PrimaryButton
              title={t("general.save")}
              submit
              className="w-full lg:w-1/4 text-center mb-8 lg:mb-0"
            />
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default inject("restaurantStore")(observer(ReservationSettings));
