import { Dialog, Transition } from "@headlessui/react";
import { PlusIcon, XIcon } from "@heroicons/react/outline";
import { ChartBarIcon, LogoutIcon, MenuAlt3Icon } from "@heroicons/react/solid";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import modalStore from "../../stores/modal.store";
import reservationStore from "../../stores/reservation.store";
import restaurantStore from "../../stores/restaurant.store";
import userStore from "../../stores/user.store";
import { MODAL_NAME_RESERVATION } from "../ReservationModal/ReservationModal";

const MobileNavbar = ({
  navigation,
  renderWithoutChilds,
  renderWithChilds,
}: any) => {
  const { t } = useTranslation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <div className="fixed border-b shadow-inner w-full bg-white">
      <button
        type="button"
        className="px-4 bg-white p-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white md:hidden relative"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <MenuAlt3Icon className="h-6 w-6" aria-hidden="true" />
        {reservationStore.openReservationCount !== 0 && (
          <div className="absolute text-[10px] top-[8px] right-[5px] bg-primary-600 rounded-full p-1 w-4 h-4 flex justify-center items-center text-white">
            {reservationStore.openReservationCount}
          </div>
        )}
      </button>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 md:hidden bg-white"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-100 pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="mt-5 h-0 flex-1 overflow-y-auto flex justify-between flex-col">
                  <div className="h-full">
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight p-6 pt-0">
                      {restaurantStore?.currentRestaurant?.name}
                    </h2>
                    <nav className="space-y-1 px-2">
                      {navigation.map((item: any) =>
                        item.children
                          ? renderWithChilds(item)
                          : renderWithoutChilds(item)
                      )}
                    </nav>
                    <div
                      className="border rounded-md p-1 flex justify-center items-center cursor-pointer m-3 mt-5"
                      onClick={() => {
                        setSidebarOpen(false);
                        modalStore.setModal(MODAL_NAME_RESERVATION, {
                          isCreate: true,
                        });
                      }}
                    >
                      <PlusIcon className="text-gray-500 w-3 h-3 mr-1" />
                      <p className="font-sm text-gray-500 text-center">
                        {t("navbar.addReservation")}
                      </p>
                    </div>
                  </div>
                  <div className="flex-shrink-0 p-4 bg-gray-100">
                    <Link
                      className="flex m-5 mb-0 text-gray-500 justify-center"
                      onClick={() => {
                        userStore.logout();
                      }}
                      to="/"
                    >
                      <LogoutIcon className="w-6 h-6 mr-3" />
                      <div> Logout</div>
                    </Link>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default MobileNavbar;
