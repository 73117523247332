export enum STATUS {
  OPEN = "OPEN",
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
  ARRIVED = "ARRIVED",
  NOT_ARRIVED = "NOT_ARRIVED",
}

export enum NotificationType {
  REQUEST = "request",
  CONFIRMATION = "confirmation",
  UPDATE = "update",
  REMINDER = "reminder",
  DECLINED = "declined",
}

export enum NotificationStatus {
  SCHEDULED = "scheduled",
  SENT = "request",
  DELIVERED = "delivered",
  SOFT_BOUNCE = "soft_bounce",
  HARD_BOUNCE = "hard_bounce",
  SPAM = "spam",
  INVALID_EMAIL = "invalid_email",
  ERROR = "error",
}

export interface NotificationDto {
  type: NotificationType;
  messageId: string;
  status: NotificationStatus;
}

export interface ReservationDto {
  _id: string;

  numberOfPersons: number;

  dateTime: Date;

  firstName: string;

  lastName: string;

  email: string;

  duration: number;

  phoneNumber: string;

  note?: string;

  status: STATUS;

  isEdit?: boolean;

  isCreate?: boolean;

  notifications?: NotificationDto[];
}
