import { action, makeAutoObservable, observable } from "mobx";

export class ModalStore {
  @observable currentModal = "";
  @observable customData: any = null;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  setModal(modalName: string, customData?: any) {
    this.currentModal = modalName;
    this.customData = customData;
    if (modalName === "") {
      this.customData = null;
    }
  }
}

const modalStore = new ModalStore();
export default modalStore;
