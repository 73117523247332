import { action, makeAutoObservable, observable } from "mobx";
import { toast } from "react-toastify";
import Axios from "../util/Axios";

export class RestaurantStore {
  @observable restaurants: any = [];
  @observable currentRestaurant: any | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  async getRestaurants() {
    if (this.restaurants.length > 0) {
      return;
    }

    const response = await Axios.get("/restaurants");
    if (response.status === 200) {
      this.restaurants = response.data;

      //Workaround cause we have only one restaurant
      this.currentRestaurant = this.restaurants[0];
    }
  }

  @action
  async update(data: any) {
    if (!this.currentRestaurant) {
      return;
    }

    const response = await Axios.put(
      `/restaurants/${this.currentRestaurant._id}`,
      data
    );

    if (response.status === 200) {
      this.currentRestaurant = response.data;
      toast.success("Restaurant updated");
    }
  }
}

const restaurantStore = new RestaurantStore();
export default restaurantStore;
