import React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import dayjs from "dayjs";
import RDatePicker from "react-datepicker";

type Props = {
  startDate: Date;
  endDate: Date;
  onDateChange: (dates: [Date, Date]) => void;
  onChevronClicked: (dates: [Date, Date]) => void;
};

const DateRangePicker = ({
  startDate,
  endDate,
  onDateChange,
  onChevronClicked,
}: Props) => {
  const interval = dayjs(endDate).diff(startDate, "day");

  const handleChevronClick = (direction: "left" | "right") => {
    let newStartDate;
    let newEndDate;

    if (direction === "left") {
      newEndDate = dayjs(startDate).toDate();
      newStartDate = dayjs(startDate).subtract(interval, "day").toDate();
    } else {
      newStartDate = dayjs(endDate).toDate();
      newEndDate = dayjs(endDate).add(interval, "day").toDate();
    }

    onChevronClicked([newStartDate, newEndDate]);
  };

  return (
    <div className="flex w-96 justify-center items-center">
      <ChevronLeftIcon
        className="w-7 cursor-pointer"
        onClick={() => handleChevronClick("left")}
      />
      <RDatePicker
        selected={startDate}
        className="font-semibold w-72 text-center cursor-pointer bg-color-none border-none outline-none bg-transparent"
        locale="de"
        dateFormat="dd.MM.yyyy"
        onChange={onDateChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        wrapperClassName="text-center"
      />
      <ChevronRightIcon
        className="w-7 cursor-pointer"
        onClick={() => handleChevronClick("right")}
      />
    </div>
  );
};

export default DateRangePicker;
