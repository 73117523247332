import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MODAL_NAME_CREATE_VACATION } from "../../../components/CreateVacationModal/CreateVacationModal";
import EmptyState from "../../../components/EmptyState/EmptyState";
import Layout from "../../../components/Layout/Layout";
import Loading from "../../../components/Loading/Loading";
import PageHeader from "../../../components/PageHeader/PageHeader";
import VacationTable from "../../../components/VacationTable/VacationTable";
import modalStore from "../../../stores/modal.store";
import vacationStore from "../../../stores/vacation.store";

const Vacation = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const load = async () => {
      const timeout = setTimeout(() => {
        setLoading(false);
      }, 3000);
      setLoading(true);
      await vacationStore.loadVacations();
      setLoading(false);
      clearTimeout(timeout);
    };
    load();
  }, []);

  const renderContent = () => {
    if (loading) {
      return <Loading />;
    }

    return (
      <div className="mt-16 lg:mt-0 ">
        <PageHeader
          title={t("vacation.title")}
          buttonName={t("vacation.buttonName")}
          onClick={() => {
            modalStore.setModal(MODAL_NAME_CREATE_VACATION);
          }}
        />
        {vacationStore.vacations.length === 0 && (
          <EmptyState
            title={t("createVacation.emptyTitle")}
            subtitle={t("createVacation.emptySubtitle")}
          />
        )}
        {vacationStore.vacations.length > 0 && (
          <div className="p-8 pt-4">
            <VacationTable />
          </div>
        )}
      </div>
    );
  };

  return <Layout>{renderContent()}</Layout>;
};

export default inject("modalStore")(observer(Vacation));
