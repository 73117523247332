import Axios from "../util/Axios";
import { action, makeAutoObservable, observable } from "mobx";
import { toast } from "react-toastify";
import { VacationDto } from "../dto/Vacation.dto";

export class VacationStore {
  @observable vacations: VacationDto[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  @action
  async loadVacations() {
    const response = await Axios.get("/vacations");
    if (response.status === 200) {
      this.vacations = response.data;
    }
  }

  @action
  async createVacation(data: VacationDto) {
    try {
      const response = await Axios.post("/vacations", data);
      this.showResponseMessage(
        response.status,
        "Urlaub erstellt",
        "Urlaub konnte nicht erstellt werden"
      );
      return response.status === 201;
    } catch (err) {
      toast.error("Urlaub konnte nicht erstellt werden");
      return false;
    }
  }

  @action
  async updateVacation(id: string, data: VacationDto) {
    try {
      const response = await Axios.put(`/Vacations/${id}`, data);
      this.showResponseMessage(
        response.status,
        "Urlaub wurde erfolgreich gespeichert",
        "Urlaub konnte nicht gespeichert werden"
      );
      return response.status === 200;
    } catch (err) {
      toast.error("Schicht konnte nicht gespeichert werden");
      return false;
    }
  }

  @action
  async deleteVacation(id: string) {
    try {
      const response = await Axios.delete(`/vacations/${id}`);
      this.showResponseMessage(
        response.status,
        "Urlaub wurde erfolgreich gelöscht",
        "Urlaub konnte nicht gelöscht werden"
      );
      return response.status === 200;
    } catch (err) {
      toast.error("Urlaub konnte nicht gelöscht werden");
      return false;
    }
  }

  private showResponseMessage(
    status: number,
    successMessage: string,
    errorMessage: string
  ) {
    if (status === 201 || status === 200) {
      toast.success(successMessage);
      this.loadVacations();
    } else {
      toast.error(errorMessage);
    }
  }
}

const vacationStore = new VacationStore();
export default vacationStore;
