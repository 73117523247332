import React from "react";
import TextField from "../TextField/TextField";

import { Controller } from "react-hook-form";
import Toogle from "../Toogle/Toogle";

interface Props {
  register: any;
  control: any;
  name: string;
  setValue: any;
  getValues: any;
  edit?: boolean;
}

const SameDayReservation = ({
  edit = true,
  register,
  control,
  name,
  setValue,
  getValues,
}: Props) => {
  return (
    <>
      <div className="grid grid-cols-6 gap-6 mt-5">
        <div className="col-span-6 sm:col-span-3">
          <Controller
            control={control}
            name={`${name}.enabled`}
            render={({ field: { ref, ...field } }) => (
              <Toogle
                disabled={!edit}
                title="Reservierung am selben Tag"
                description=" Soll eine Reservierung am selben Tag möglich sein? Wenn ja, bis wann?"
                {...field}
                onChange={(checked: boolean) => {
                  field.onChange(checked);
                  if (!checked) {
                    setValue(`${name}.until`, "");
                  }
                }}
              />
            )}
          />
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="sameDayReservation"
            className="block text-sm font-medium text-gray-700"
          >
            Bis
          </label>
          <TextField
            register={register(`${name}.until`, {
              validate: (value: any) => {
                const enabled = getValues(`${name}.enabled`);

                if (enabled && !value) {
                  return false;
                }

                return true;
              },
            })}
            placeholder=""
            title=""
            type="time"
            hideTitle
            disabled={!edit}
          />
        </div>
      </div>
    </>
  );
};

export default SameDayReservation;
