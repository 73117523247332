import React from "react";
import { useTranslation } from "react-i18next";
import emptyIcon from "../../assets/empty.svg";
import emptyIcon2 from "../../assets/empty2.svg";
import emptyIcon3 from "../../assets/empty3.svg";

interface Props {
  title: string;
  subtitle: string;
}

const EmptyState = ({ title, subtitle }: Props) => {
  const { t } = useTranslation();

  let img = emptyIcon;
  const randomNumber = Math.floor(Math.random() * 3) + 1;

  if (randomNumber === 1) {
    img = emptyIcon;
  } else if (randomNumber === 2) {
    img = emptyIcon2;
  } else {
    img = emptyIcon3;
  }

  return (
    <div className="w-full h-full flex justify-center items-center flex-col">
      <img src={img} alt="Not Found" className="h-64" />
      <h3 className="mt-2 text-sm font-bold text-gray-900">{t(title)}</h3>
      <p className="mt-1 text-sm text-gray-500">{t(subtitle)}</p>
    </div>
  );
};

export default EmptyState;
