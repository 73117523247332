import React from "react";
import { Trans } from "react-i18next";
import { ReservationDto, STATUS } from "../../dto/Reservation.dto";

interface Props {
  reservations?: ReservationDto[];
  reservationStats?: { count: number; totalPersons: number };
}

const TranslateReservationHeader = ({
  reservations,
  reservationStats,
}: Props) => {
  let totalPersons;
  let reservationCount;

  if (reservations) {
    totalPersons = reservations
      .filter((cur) => cur.status !== STATUS.DECLINED)
      .reduce((acc, cur) => acc + cur.numberOfPersons, 0);

    reservationCount = reservations.filter(
      (cur) => cur.status !== STATUS.DECLINED
    ).length;
  }

  if (reservationStats) {
    totalPersons = reservationStats.totalPersons;
    reservationCount = reservationStats.count;
  }

  const transKey =
    reservationCount === 1
      ? "reservation.statInfoReservationOne"
      : "reservation.statInfoReservationMultiple";

  return (
    <Trans
      i18nKey={transKey}
      count={totalPersons ?? 0}
      values={{
        reservationCount: reservationCount ?? 0,
        totalPersons: totalPersons ?? 0,
      }}
    />
  );
};

export default TranslateReservationHeader;
