import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Installation from "../pages/Installation/Installation";
import Login from "../pages/Login/Login";
import Reservation from "../pages/Reservation/Reservation";
import ReservationNew from "../pages/ReservationNew/ReservationNew";
import ReservationOverview from "../pages/ReservationOverview/ReservationOverview";
import General from "../pages/settings/General/General";
import ShiftSettings from "../pages/settings/Shift/ShiftSettings";
import Vacation from "../pages/settings/Vacation/Vacation";
import Widget from "../pages/settings/Widget/Widget";
import Shift from "../pages/Shifts/Shift";
import reservationStore from "../stores/reservation.store";
import ReservationSettings from "../pages/settings/Reservation/ReservationSettings";

const AppRouter = () => {
  useEffect(() => {
    const interval = setInterval(() => {
      reservationStore.getAllOpen();
    }, 1000 * 60 * (process.env.REACT_APP_REFRESH_INTERVAL_IN_MIN ? parseInt(process.env.REACT_APP_REFRESH_INTERVAL_IN_MIN) : 5));

    return () => clearInterval(interval);
  }, []);
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/:id/reservations-all" element={<Reservation />} />
          <Route path="/:id/reservations-open" element={<ReservationNew />} />
          <Route
            path="/:id/reservations-overview"
            element={<ReservationOverview />}
          />
          <Route path="/:id/shift" element={<Shift />} />
          <Route path="/:id/settings/general" element={<General />} />
          <Route path="/:id/settings/vacation" element={<Vacation />} />
          <Route path="/:id/settings/widget" element={<Widget />} />
          <Route path="/:id/settings/installation" element={<Installation />} />
          <Route path="/:id/settings/shift" element={<ShiftSettings />} />
          <Route path="/:id/settings/reservation" element={<ReservationSettings />} />
          <Route path="*" element={<Login />} />
        </Routes>
      </Router>
    </div>
  );
};

export default AppRouter;
