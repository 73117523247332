import { inject } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LoadingAnimation } from "../../components/Loading/Loading";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import TextField from "../../components/TextField/TextField";
import { RestaurantStore } from "../../stores/restaurant.store";
import { UserStore } from "../../stores/user.store";
import packageJson from "../../../package.json";

interface LoginProps {
  userStore?: UserStore;
  restaurantStore?: RestaurantStore;
}

const Login = ({ userStore, restaurantStore }: LoginProps) => {
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit } = useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const process = useCallback(async () => {
    await restaurantStore?.getRestaurants();
    setLoading(false);
    if (restaurantStore?.currentRestaurant) {
      navigate(`${restaurantStore?.currentRestaurant._id}/reservations-open`);
    } else {
      navigate("/reservations-open");
    }
  }, [restaurantStore, navigate]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    if (await userStore?.login(data.email, data.password)) {
      process();
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    const jwt = localStorage.getItem("essen24_jwt");
    if (jwt) {
      process();
    }
  }, [process]);

  return (
    <div className=" h-screen bg-light-gray gradient">
      <div className="min-h-full flex flex-col justify-center h-full">
        <div className="mx-auto w-full max-w-md lg:h-auto h-full">
          <div className="shadow sm:rounded-lg h-full lg:h-auto ">
            <>
              {loading && (
                <div className="mt-5 bg-white pt-8 pb-8 rounded-md ">
                  <LoadingAnimation />
                </div>
              )}
              {!loading && (
                <form onSubmit={handleSubmit(onSubmit)} className="lg:h-auto h-full flex flex-col">
                  <div className="bg-white px-4 pt-8 pb-8 rounded-md rounded-b-none flex-grow">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md ">
                      <img
                        className="mx-auto h-12 w-auto"
                        src="./essen24logo.png"
                        alt="Workflow"
                      />
                    </div>
                    <div className="mt-8">
                      <TextField
                        register={register("email", { required: true })}
                        title="login.email"
                        placeholder="login.emailPlaceholder"
                        hideTitle
                      />
                      <div className="mt-4">
                        <TextField
                          type="password"
                          register={register("password", { required: true })}
                          title="login.password"
                          placeholder="login.passwordPlaceholder"
                          hideTitle
                        />
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-100 p-4 rounded-b-md flex justify-end mt-0">
                    <PrimaryButton
                      className="w-1/3 flex justify-center"
                      title="login.submit"
                      submit
                    />
                  </div>
                </form>
              )}
            </>
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 left-0 p-4 text-gray-500">
        V{packageJson.version}
      </div>
    </div>
  );
};

export default inject("userStore", "restaurantStore")(Login);
