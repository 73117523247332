import ModalStore from "./modal.store";
import ReservationStore from "./reservation.store";
import RestaurantStore from "./restaurant.store";
import SettingsStore from "./settings.store";
import ShiftStore from "./shift.store";
import UserStore from "./user.store";
import VacationStore from "./vacation.store";

export const stores = {
  userStore: UserStore,
  reservationStore: ReservationStore,
  modalStore: ModalStore,
  shiftStore: ShiftStore,
  settingsStore: SettingsStore,
  restaurantStore: RestaurantStore,
  vacationStore: VacationStore,
};
