import { action, makeAutoObservable, observable } from "mobx";
import Axios from "../util/Axios";

export class SettingsStore {
  @observable restaurant: any = [];

  constructor() {
    makeAutoObservable(this);
  }

  @action
  async create(data: any) {
    //TODO: Change to real id after multiple
    const response = await Axios.put("/restaurants/0", data);
    if (response.status === 201) {
    }
  }
}

const settingsStore = new SettingsStore();
export default settingsStore;
