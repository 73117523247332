import React, { useEffect } from "react";
import reservationStore from "../../stores/reservation.store";
import AuthContainer from "../Authcontainer/AuthContainer";
import Navbar from "../Navbar/Navbar";
import "./Layout.css";

const Layout = ({ children }: any) => {
  useEffect(() => {
    reservationStore?.getAllOpen();
  }, [reservationStore]);
  return (
    <AuthContainer>
      <div className="bg-white h-full min-h-screen">
        <Navbar />
        <div className="md:pl-64 flex flex-col flex-1 bg-light-gray min-h-screen">
          <main className="flex-1 mt-12 lg:mt-0">{children}</main>
        </div>
      </div>
    </AuthContainer>
  );
};

export default Layout;
