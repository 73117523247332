import dayjs from "dayjs";
import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { prepareData, ShiftStore } from "../../stores/shift.store";
import DatePicker from "../DatePicker/DatePicker";
import DayPicker from "../DayPicker/DayPicker";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import TextArea from "../TextArea/TextArea";
import TextField from "../TextField/TextField";
import SameDayReservation from "../SameDayReservation/SameDayReservation";
import { Time } from "../../dto/Shift.dto";

interface Props {
  shift: any;
  edit: boolean;
  shiftStore?: ShiftStore;
  onClose: () => void;
}

const timeToString = (time: Time): string | undefined => {
  if (time) {
    return `${time.hours.toString().padStart(2, "0")}:${time.minutes
      .toString()
      .padStart(2, "0")}`;
  } else {
    return undefined;
  }
};

const ShiftDetails = ({ shift, edit = false, shiftStore, onClose }: Props) => {
  const { register, handleSubmit, control, setValue, getValues } = useForm();
  const { t } = useTranslation();

  useEffect(() => {
    for (let key of Object.keys(shift)) {
      if (key === "daysOfWeek") {
        const daysOfWeek: any = {
          MONDAY: false,
          TUESDAY: false,
          WEDNESDAY: false,
          THURSDAY: false,
          FRIDAY: false,
          SATURDAY: false,
          SUNDAY: false,
        };

        for (let dayKey of shift[key]) {
          daysOfWeek[dayKey] = true;
        }
        setValue(key, daysOfWeek);
      } else if (
        dayjs(shift[key]).isValid() &&
        dayjs(shift[key]).year() > 2000
      ) {
        setValue(key, dayjs(shift[key]).toDate());
      } else {
        setValue(key, shift[key]);
      }
    }
  }, [shift, setValue]);

  useEffect(() => {
    if (shift.firstSeating) {
      setValue("firstSeating", timeToString(shift.firstSeating));
    }

    if (shift.lastSeating) {
      setValue("lastSeating", timeToString(shift.lastSeating));
    }

    if (shift.sameDayReservation?.enabled) {
      setValue("sameDayReservation.enabled", shift.sameDayReservation?.enabled);
      setValue(
        "sameDayReservation.until",
        timeToString(shift.sameDayReservation.until)
      );
    }
  }, [shift, setValue]);

  if (!shift) {
    return <></>;
  }

  const onSubmit = async (data: any) => {
    const updatedShift = prepareData(data);
    if (await shiftStore?.updateShift(data._id, updatedShift)) {
      onClose();
    }
  };

  return (
    <form className="space-y-8" onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-8  sm:space-y-5">
        <div className="space-y-6 sm:space-y-5">
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              {t("createShift.name")}
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <TextField
                register={register("name", {
                  required: true,
                  pattern: /[a-zA-Z]+/,
                  min: 3,
                  max: 30,
                })}
                placeholder="Name"
                title=""
                hideTitle
                disabled={!edit}
              />
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              {t("createShift.capacity")}
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <TextField
                register={register("capacity", {
                  required: true,
                  pattern: /[0-9]+ /,
                  valueAsNumber: true,
                })}
                placeholder={t("createShift.capacityPlaceholder")}
                title=""
                hideTitle
                disabled={!edit}
              />
            </div>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              {t("createShift.interval")}
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <TextField
                register={register("interval", {
                  pattern: /[0-9]+ /,
                  valueAsNumber: true,
                  required: true,
                })}
                placeholder={t("createShift.intervalPlaceholder")}
                title=""
                hideTitle
                disabled={!edit}
              />
            </div>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
            <label className="block text-sm font-medium text-gray-700  sm:pt-2">
              {t("createShift.duration")}
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <TextField
                register={register("duration", {
                  pattern: /[0-9]+ /,
                  valueAsNumber: true,
                  required: true,
                })}
                placeholder={t("createShift.durationDetails")}
                title=""
                hideTitle
                disabled={!edit}
              />
            </div>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            {t("createShift.notes")}
          </label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <TextArea
              register={register("notes", {
                pattern: /[a-zA-Z]+/,
              })}
              placeholder={t("createShift.notesPlaceholder")}
              hideTitle
              disabled={!edit}
            />
          </div>
        </div>
      </div>
      <div className="mt-5 border-t pt-5">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          {t("createShift.timeDate")}
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          {t("createShift.timeDateDetails")}
        </p>
      </div>
      <DayPicker control={control} fieldName="daysOfWeek" disabled={!edit} />
      <div className="grid grid-cols-6 gap-6 mt-5">
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium text-gray-700"
          >
            {t("createShift.validFrom")}
          </label>
          <DatePicker
            fieldName="startDate"
            rules={{}}
            control={control}
            disabled={!edit}
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="last-name"
            className="block text-sm font-medium text-gray-700"
          >
            {t("createShift.validTo")}
          </label>
          <DatePicker
            fieldName="endDate"
            rules={{}}
            control={control}
            disabled={!edit}
          />
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium text-gray-700"
          >
            {t("createShift.firstSeating")}
          </label>
          <TextField
            register={register("firstSeating", { required: true })}
            placeholder={t("createShift.firstSeatingPlaceholder")}
            title=""
            type="time"
            hideTitle
            disabled={!edit}
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="last-name"
            className="block text-sm font-medium text-gray-700"
          >
            {t("createShift.lastSeating")}
          </label>
          <TextField
            register={register("lastSeating", { required: true })}
            placeholder={t("createShift.lastSeatingPlaceholder")}
            title=""
            type="time"
            hideTitle
            disabled={!edit}
          />
        </div>
      </div>
      <div className="mt-5 border-t pt-5">
        <SameDayReservation
          control={control}
          register={register}
          setValue={setValue}
          getValues={getValues}
          edit={edit}
          name="sameDayReservation"
        ></SameDayReservation>
      </div>
      <div className="flex justify-end mt-10">
        {edit && (
          <PrimaryButton
            submit
            title={t("createShift.save")}
            className="relative inline-flex items-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
          />
        )}
      </div>
    </form>
  );
};

export default inject("shiftStore")(observer(ShiftDetails));
