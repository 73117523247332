import React, { useState, Fragment, useEffect } from "react";
import { Disclosure } from "@headlessui/react";
import {
  BellIcon,
  CalendarIcon,
  FireIcon,
  AdjustmentsIcon,
  LogoutIcon,
  PlusIcon,
} from "@heroicons/react/outline";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { RestaurantStore } from "../../stores/restaurant.store";
import reservationStore from "../../stores/reservation.store";
import userStore from "../../stores/user.store";
import MobileNavbar from "./MobileNavbar";
import { useTranslation } from "react-i18next";
import modalStore from "../../stores/modal.store";
import { MODAL_NAME_RESERVATION } from "../ReservationModal/ReservationModal";

const navigation = [
  {
    name: "Reservierungsanfragen",
    href: "/reservations-open",
    counter: true,
    icon: BellIcon,
  },
  {
    name: "Heutige Reservierungen",
    href: "/reservations-overview",
    icon: FireIcon,
  },
  {
    name: "Alle Reservierungen",
    href: "/reservations-all",
    icon: CalendarIcon,
  },
  {
    name: "Einstellungen",
    icon: AdjustmentsIcon,
    href: "/settings",
    children: [
      {
        name: "Stammdaten",
        href: "/settings/general",
        icon: CalendarIcon,
      },
      {
        name: "Reservierungen",
        href: "/settings/reservation",
        icon: CalendarIcon,
      },
      {
        name: "Schichten",
        href: "/settings/shift",
        icon: CalendarIcon,
      },
      {
        name: "Urlaub",
        href: "/settings/vacation",
        icon: CalendarIcon,
      },
    ],
  },
];

interface Props {
  restaurantStore?: RestaurantStore;
}

const Navbar = ({ restaurantStore }: Props) => {
  const { t } = useTranslation();
  const [currentRoute, setCurrentRoute] = useState(window.location.toString());
  const selectedRestaurantId = restaurantStore?.currentRestaurant
    ? restaurantStore?.currentRestaurant._id
    : "";

  useEffect(() => {
    userStore.loadMe();
  }, []);

  const renderWithChilds = (item: any) => {
    const active = currentRoute.includes(item.href);

    return (
      <Disclosure
        as="div"
        key={item.name}
        className="space-y-1"
        defaultOpen={active}
      >
        {({ open }) => (
          <>
            <Disclosure.Button
              className={classNames(
                active
                  ? "bg-gray-100 text-gray-900 hover:text-gray-900 hover:bg-gray-100"
                  : "text-gray-600 hover:text-gray-900 hover:bg-gray-50",
                "w-full group flex items-center px-2 py-2 text-sm font-medium rounded-md flex justify-between "
              )}
            >
              <div className="flex justify-center">
                <item.icon
                  className={classNames(
                    active
                      ? "text-gray-500"
                      : "text-gray-400 group-hover:text-gray-500",
                    "mr-3 flex-shrink-0 h-6 w-6"
                  )}
                  aria-hidden="true"
                />
                <span className="flex-1">{item.name}</span>
              </div>
              <svg
                className={classNames(
                  open ? "text-gray-500 rotate-90" : "text-gray-400",
                  "ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                )}
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
              </svg>
            </Disclosure.Button>
            <Disclosure.Panel className="space-y-1">
              {item.children.map((subItem: any) => {
                const subActive = currentRoute.includes(subItem.href);
                return (
                  <Link
                    key={subItem.name}
                    to={`/${selectedRestaurantId}${subItem.href}`}
                    onClick={() => {
                      setCurrentRoute(subItem.href);
                    }}
                    className={classNames(
                      subActive
                        ? "bg-gray-100 text-gray-900 hover:text-gray-900 hover:bg-gray-100"
                        : "text-gray-600 hover:text-gray-900 hover:bg-gray-50",
                      "group flex items-center px-5 py-2 text-sm font-medium rounded-md"
                    )}
                  >
                    {subItem.name}
                  </Link>
                );
              })}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    );
  };

  const generateName = () => {
    if (userStore.currentUser) {
      const split = userStore.currentUser.fullName.split(" ");
      return `${split[0].charAt(0).toUpperCase()}${
        split[1] ? split[1].charAt(0).toUpperCase() : ""
      }`;
    }
    return "UU";
  };

  const renderWithoutChilds = (item: any) => {
    const active = currentRoute.includes(item.href);
    return (
      <Link
        key={item.name}
        to={`/${selectedRestaurantId}${item.href}`}
        onClick={() => {
          setCurrentRoute(item.href);
        }}
        className={classNames(
          active
            ? "bg-gray-100 text-gray-900 hover:text-gray-900 hover:bg-gray-100"
            : "text-gray-600 hover:text-gray-900 hover:bg-gray-50",
          "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
        )}
      >
        <div className="relative">
          <item.icon
            className={classNames(
              active
                ? "text-gray-500"
                : "text-gray-400 group-hover:text-gray-500",
              "mr-3 flex-shrink-0 h-6 w-6"
            )}
            aria-hidden="true"
          />
          {item.counter && reservationStore.openReservationCount !== 0 && (
            <div className="absolute text-[10px] top-[-2px] right-[5px] bg-primary-600 rounded-full p-1 w-4 h-4 flex justify-center items-center text-white">
              {reservationStore.openReservationCount}
            </div>
          )}
        </div>
        <span className="flex-1">{item.name}</span>
      </Link>
    );
  };

  return (
    <div className=" shadow-inner">
      <MobileNavbar
        navigation={navigation}
        renderWithChilds={renderWithChilds}
        renderWithoutChilds={renderWithoutChilds}
      />
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex-1 flex flex-col min-h-0 bg-white">
          <div className="flex-1 flex flex-col overflow-y-auto">
            <div>
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight p-6 pb-0">
                {restaurantStore?.currentRestaurant?.name}
              </h2>
              <nav className="flex-1 bg-white p-4" aria-label="Sidebar">
                {navigation.map((item) =>
                  item.children
                    ? renderWithChilds(item)
                    : renderWithoutChilds(item)
                )}
              </nav>
              <div
                className="border rounded-md p-1 flex justify-center items-center cursor-pointer m-3 bg-white"
                onClick={() => {
                  modalStore.setModal(MODAL_NAME_RESERVATION, {
                    isCreate: true,
                  });
                }}
              >
                <PlusIcon className="text-gray-500 w-3 h-3 mr-1" />
                <p className="font-sm text-gray-500 text-center">
                  {t("navbar.addReservation")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-shrink-0 p-4 bg-white">
          <div className="flex flex-shrink-0 border-t border-gray-100 p-4">
            <div className="flex items-center">
              <div className="w-10 h-10 rounded-full flex justify-center items-center font-bold text-sm gradient cursor-default">
                {generateName()}
              </div>
              <div className="ml-3">
                <p className="text-sm font-medium text-gray">{`${
                  userStore.currentUser ? userStore.currentUser.fullName : ""
                }`}</p>
                <Link
                  className="flex text-gray-500 text-sm items-center"
                  onClick={() => {
                    userStore.logout();
                  }}
                  to="/"
                >
                  <LogoutIcon className="w-4 h-4 mr-1" />
                  <div>Logout</div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default inject("restaurantStore")(observer(Navbar));
