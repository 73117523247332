import { inject, observer } from "mobx-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ModalStore } from "../../stores/modal.store";
import DatePicker from "../DatePicker/DatePicker";
import CardHeading from "../ModalCard/CardHeading";
import ModalCard from "../ModalCard/ModalCard";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import { useForm } from "react-hook-form";
import { ShiftStore } from "../../stores/shift.store";
import Information from "../Information/Information";
import Confirm from "../Confirm/Confirm";
import vacationStore from "../../stores/vacation.store";
import dayjs from "dayjs";

export const MODAL_NAME_CREATE_VACATION = "CreateVacationModal";

interface createVacationModalProps {
  modalStore?: ModalStore;
  shiftStore?: ShiftStore;
}

const CreateVacationModal = ({ modalStore }: createVacationModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const { handleSubmit, control, watch, reset, getValues } = useForm();

  if (modalStore?.currentModal !== MODAL_NAME_CREATE_VACATION) {
    return <></>;
  }

  const onSubmit = async (data: any) => {
    setIsOpen(true);
  };

  const isDaySelectable = (date: Date) => {
    let selectedDays: string[] = [];

    var getDaysArray = function (start: Date, end: Date) {
      for (
        var arr = [], dt = new Date(start);
        dt <= new Date(end);
        dt.setDate(dt.getDate() + 1)
      ) {
        arr.push(dayjs(dt).format("YYYY-MM-DD"));
      }
      return arr;
    };

    for (const closeDate of vacationStore.vacations ?? []) {
      selectedDays.push(
        ...getDaysArray(new Date(closeDate.from), new Date(closeDate.to))
      );
    }

    return !selectedDays.includes(dayjs(date).format("YYYY-MM-DD"));
  };

  return (
    <ModalCard isOpen={modalStore?.currentModal === MODAL_NAME_CREATE_VACATION}>
      <div className="w-full h-full flex justify-end absolute top-0 left-0 right-0 bottom-0">
        <div className="lg:w-1/3 w-full bg-white min-h-screen p-5 shadow-md">
          <CardHeading title={t("createVacation.title")} className="mb-5" />
          <Information text={t("createVacation.help")} />
          <form className="space-y-8" onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-8  sm:space-y-5">
              <div className="space-y-6 sm:space-y-5">
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <div className="mt-6">
                    <label className="block text-sm font-medium text-gray-700">
                      {t("createVacation.from")}
                    </label>
                    <DatePicker
                      fieldName="from"
                      rules={{ required: true }}
                      control={control}
                      defaultValue={new Date()}
                      startDate={new Date()}
                      filterDate={isDaySelectable}
                    />
                  </div>
                  <div className="mt-3">
                    <label className="block text-sm font-medium text-gray-700">
                      {t("createVacation.to")}
                    </label>
                    <DatePicker
                      fieldName="to"
                      rules={{ required: true }}
                      control={control}
                      startDate={watch("from") ? watch("from") : new Date()}
                      defaultValue={watch("from")}
                      filterDate={isDaySelectable}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <PrimaryButton
                submit
                title={t("createShift.save")}
                className="relative inline-flex items-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 w-1/2"
              />
            </div>
          </form>
        </div>
      </div>
      <Confirm
        isOpen={isOpen}
        setOpen={() => {}}
        title={t("createVacation.confirmTitle")}
        description={t("createVacation.confirmDescription")}
        confirmText={t("createVacation.confirmText")}
        cancelText={t("createVacation.cancelText")}
        onCancel={() => {
          setIsOpen(false);
          reset();
          modalStore?.setModal("");
        }}
        onConfirm={async () => {
          if (
            await vacationStore?.createVacation({
              from: getValues("from"),
              to: getValues("to"),
            })
          ) {
            setIsOpen(false);

            reset();
            modalStore?.setModal("");
          }
        }}
      />
    </ModalCard>
  );
};

export default inject(
  "modalStore",
  "vacationStore"
)(observer(CreateVacationModal));
