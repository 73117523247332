import { inject, observer } from "mobx-react";
import React, { useState } from "react";
import dayjs from "dayjs";
import Loading from "../Loading/Loading";
import { useTranslation } from "react-i18next";
import { VacationStore } from "../../stores/vacation.store";
import { TrashIcon } from "@heroicons/react/outline";
import Confirm from "../Confirm/Confirm";

interface Props {
  vacationStore?: VacationStore;
}

const VacationTable = ({ vacationStore }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [loading] = useState(false);
  const [headers] = useState<string[]>(["", "from", "to"]);
  const [currentEntry, setCurrentEntry] = useState<any>(null);

  const renderHeader = () => {
    return headers.map((item, key) => (
      <th
        key={item + key}
        scope="col"
        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
      >
        {item !== "" && t("vacation." + item)}
      </th>
    ));
  };

  const renderRows = () => {
    const renderValue = (value: any) => {
      if (Array.isArray(value)) {
        return value.join(",");
      }
      if (typeof value == "boolean") {
        return (
          <span
            className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium  ${
              value ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"
            } `}
          >
            {value ? "Aktiv" : "Deaktiviert"}
          </span>
        );
      }
      if (value && dayjs(value).isValid()) {
        return dayjs(value).format("DD.MM.YYYY");
      }
      return value;
    };

    return vacationStore?.vacations.map((entry: any, key) => {
      return (
        <tr key={entry._id} className="hover:bg-slate-100 ">
          {headers.map((item: string, keyItem: number) => (
            <td
              className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
              key={entry._id + item}
            >
              {renderValue(entry[item])}
            </td>
          ))}
          <td
            onClick={() => {
              setCurrentEntry(entry);
              setIsOpen(true);
            }}
            className="cursor-pointer whitespace-nowrap px-3 py-4 text-sm  text-primary-600 hover:text-primary-900"
          >
            <TrashIcon className="w-5 h-5" />
          </td>
        </tr>
      );
    });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col w-full">
      <div className="inline-block min-w-full align-middle">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                {renderHeader()}
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                ></th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {renderRows()}
            </tbody>
          </table>
        </div>
      </div>
      <Confirm
        title={t("vacation.confirmTitle")}
        description={t("vacation.confirmDescription")}
        cancelText={t("vacation.cancelCancel")}
        confirmText={t("vacation.confirmConfirm")}
        onConfirm={() => {
          if (currentEntry) {
            vacationStore?.deleteVacation(currentEntry._id);
          }
          setCurrentEntry(null);
          setIsOpen(false);
        }}
        onCancel={() => {
          setIsOpen(false);
          setCurrentEntry(null);
        }}
        setOpen={() => {
          setIsOpen(false);
        }}
        isOpen={isOpen}
      />
    </div>
  );
};

export default inject("vacationStore")(observer(VacationTable));
