import { inject, observer } from "mobx-react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Layout from "../../../components/Layout/Layout";
import PageHeader from "../../../components/PageHeader/PageHeader";
import PrimaryButton from "../../../components/PrimaryButton/PrimaryButton";
import TextField from "../../../components/TextField/TextField";
import { RestaurantStore } from "../../../stores/restaurant.store";

interface Props {
  restaurantStore?: RestaurantStore;
}

const General = ({ restaurantStore }: Props) => {
  const { t } = useTranslation();
  const { handleSubmit, register, setValue } = useForm();

  useEffect(() => {
    const curr = restaurantStore?.currentRestaurant;
    if (!curr) {
      return;
    }

    for (let key of Object.keys(curr)) {
      setValue(key, curr[key]);
    }
  }, [restaurantStore?.currentRestaurant, setValue]);

  const onSubmit = (data: any) => {
    restaurantStore?.update(data);
  };

  return (
    <Layout>
      <div className="h-screen mt-16 lg:mt-0 ">
        <PageHeader title={t("general.title")} disableButton />
        <form className="space-y-8  px-8 " onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-8  sm:space-y-5">
            <div className="space-y-6 sm:space-y-5  shadow rounded-md border p-3 bg-white">
              <h3 className="font-bold mb-3">{t("general.general")}</h3>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px"
                >
                  {t("general.name")}
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <TextField
                    register={register("name", {})}
                    placeholder="Name"
                    title=""
                    disabled
                    hideTitle
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  {t("general.email")}
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <TextField
                    register={register("email", {})}
                    placeholder="E-Mail"
                    title=""
                    hideTitle
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  {t("general.phoneNumber")}
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <TextField
                    register={register("phoneNumber", {
                      required: true,
                    })}
                    placeholder={t("general.phoneNumber")}
                    title=""
                    hideTitle
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
                <label
                  htmlFor="homepage"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  {t("general.homepage")}
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <TextField
                    register={register("homepage", {
                      required: true,
                    })}
                    placeholder={t("general.homepage")}
                    title=""
                    hideTitle
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white  shadow rounded-md border p-3">
            <h3 className="font-bold mb-3">{t("general.address")}</h3>
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6">
                <label
                  htmlFor="street-address"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("general.street")}
                </label>
                <TextField
                  register={register("address.street", {
                    required: true,
                  })}
                  placeholder={t("general.street")}
                  title=""
                  hideTitle
                />
              </div>
              <div className="col-span-6 sm:col-span-6 lg:col-span-1">
                <label
                  htmlFor="postal-code"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("general.zip")}
                </label>
                <TextField
                  register={register("address.zip", {
                    required: true,
                  })}
                  placeholder={t("general.zip")}
                  title=""
                  hideTitle
                />
              </div>
              <div className="col-span-6 sm:col-span-6 lg:col-span-5">
                <label
                  htmlFor="city"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("general.city")}
                </label>
                <TextField
                  register={register("address.city", {
                    required: true,
                  })}
                  placeholder={t("general.city")}
                  title=""
                  hideTitle
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            <PrimaryButton
              title={t("general.save")}
              submit
              className="w-full lg:w-1/4 text-center mb-8 lg:mb-0"
            />
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default inject("restaurantStore")(observer(General));
