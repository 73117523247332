import { action, makeAutoObservable, observable } from "mobx";
import { toast } from "react-toastify";
import Axios from "../util/Axios";

export class UserStore {
  @observable currentUser: any;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  async login(email: string, password: string) {
    try {
      const response = await Axios.post("/auth/sign-in", {
        email,
        password,
      });
      if (response.status === 200) {
        const { fullName, email, accessToken, refreshToken } = response.data;
        this.currentUser = { email, fullName };
        localStorage.setItem("essen24_jwt", accessToken);
        localStorage.setItem("essen24_refreshToken", refreshToken);
        return true;
      }
      toast.error("You are unauthorized. Please check your credentials.");
      return false;
    } catch (error) {
      toast.error("You are unauthorized. Please check your credentials.");
      return false;
    }
  }

  @action
  async loadMe() {
    const token = localStorage.getItem("essen24_jwt");
    if (!token) {
      return;
    }

    const response = await Axios.get(`users/me`);
    if (response.status === 200) {
      this.currentUser = response.data;
    }
  }

  @action
  logout() {
    localStorage.clear();
    window.location.href = "";
  }
}

const userStore = new UserStore();
export default userStore;
