import React from "react";
import { ReservationDto } from "../../dto/Reservation.dto";
import { TFunction, Trans } from "react-i18next";
import TranslateReservationHeader from "./TranslateReservationHeader";

type ReservationDayItemProps = {
  title: string;
  reservations: ReservationDto[];
  renderRow: (reservation: ReservationDto, index: number) => React.ReactNode;
};

const ReservationDayItem = ({
  title,
  reservations,
  renderRow,
}: ReservationDayItemProps) => {

  return (
    <div className=" bg-gray-100 rounded-md overflow-hidden p-2">
      <div className="flex flex-col lg:flex-row items-left lg:items-center">
        <div className="px-4 pb-4 lg:py-4 pl-0 font-bold text-gray-700">{title}</div>
        <div className="px-4 pb-4 lg:py-4 pl-0 text-gray-700">
          <TranslateReservationHeader reservations={reservations} />
        </div>
      </div>
      <div className="bg-white rounded-md w-full flex flex-col">
        {reservations.map((item, index) => renderRow(item, index))}
      </div>
    </div>
  );
};

export default ReservationDayItem;
