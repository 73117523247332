import { CheckCircleIcon, PencilAltIcon, XIcon } from "@heroicons/react/solid";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HomeLayout from "../../components/Layout/Layout";
import Loading from "../../components/Loading/Loading";
import PageHeader from "../../components/PageHeader/PageHeader";
import { MODAL_NAME_RESERVATION } from "../../components/ReservationModal/ReservationModal";
import ReservationDayItem from "../../components/ReserverationDayItem/ReservationDayItem";
import modalStore from "../../stores/modal.store";
import reservationStore from "../../stores/reservation.store";
import SearchInput from "../../components/SearchInput/SearchInput";
import EmptyState from "../../components/EmptyState/EmptyState";
import Confirm from "../../components/Confirm/Confirm";
import { toast } from "react-toastify";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";

const ReservationOverview = () => {
  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [groupedData, setGroupedData] = useState<any>([]);
  const [arrivedModalOpen, setArrivedModalOpen] = useState(false);
  const [notArrivedModalOpen, setNotArrivedModalOpen] = useState(false);
  const [currentReservation, setCurrentReservation] = useState<any>(undefined);
  const { t } = useTranslation();

  const loadReservations = async (filter?: string) => {
    setLoading(true);
    reservationStore.todayReservations = [];
    await reservationStore.getAllTodayReservations(filter);
    setLoading(false);
  };

  useEffect(() => {
    loadReservations(search);
  }, [search]);

  useEffect(() => {
    if (reservationStore.todayReservations) {
      const data: any = {};
      for (const reservation of reservationStore.todayReservations) {
        const time = dayjs(reservation.dateTime).format("HH:mm");
        if (!data[time]) {
          data[time] = [];
        }
        data[time].push(reservation);
      }
      setGroupedData(data);
    }
  }, [reservationStore.todayReservations]);

  // if (loading) {
  //   return <Loading center={false} />;
  // }

  return (
    <HomeLayout>
      <PageHeader
        title={`${t("reservation.reservationAt")} ${dayjs().format(
          "DD.MM.YYYY"
        )}`}
        disableButton
      />
      <div className="p-6">
        <SearchInput setSearch={setSearch} />
      </div>
      <div className="p-6">
        {!loading && Object.keys(groupedData).length === 0 ? (
          <div className="mt-5">
            <EmptyState
              title={"reservationToday.emptyTitle"}
              subtitle={"reservationToday.emptySubtitle"}
            />
          </div>
        ) : (
          ""
        )}
        {loading && <Loading center={false} />}
        {Object.keys(groupedData).map((key: string) => {
          return (
            <div className="mb-8">
              <ReservationDayItem
                title={key}
                reservations={groupedData[key]}
                renderRow={(reservation, index) => {
                  return (
                    <div
                      key={reservation._id}
                      className={`border-b lg:flex-nowrap flex-wrap lg:flex-none flex flex-col lg:flex-row pb-3 lg:pb-0 ${
                        index % 2 === 0 ? "bg-gray-50" : "bg-white"
                      }`}
                    >
                      <ReactTooltip id="note-tooltip"  className="w-64 max-w-3xl whitespace-pre-wrap overflow-hidden" />
                      <div className="whitespace-nowrap text-sm font-medium text-gray-900 p-3 w-1/6">
                        {reservation.numberOfPersons === 1
                          ? `${reservation.numberOfPersons} ${t("person")}`
                          : `${reservation.numberOfPersons} ${t("persons")}`}
                      </div>
                      <div className="whitespace-nowrap text-sm font-medium text-gray-900 p-3 w-1/6">
                        {`${reservation.firstName} ${reservation.lastName}`}
                      </div>
                      <div className="whitespace-nowrap text-sm font-medium text-gray-900  p-3 w-1/6">
                        <a
                          href={`mailto:${reservation.email}`}
                          className="underline"
                        >
                          {reservation.email}
                        </a>
                      </div>
                      <div className="whitespace-nowrap text-sm font-medium text-gray-900  p-3 w-1/6">
                        <a
                          href={`tel:${reservation.phoneNumber}`}
                          className="underline"
                        >
                          {reservation.phoneNumber}
                        </a>
                      </div>
                      <a
                        data-tooltip-id="note-tooltip"
                        data-tooltip-content={reservation.note}
                        className="whitespace-nowrap text-sm font-medium text-gray-900 p-3 overflow-hidden text-ellipsis w-full lg:w-1/6"
                      >
                        {reservation.note}
                      </a>
                      <div
                        key={`actions-${reservation._id}`}
                        className="flex justify-end flex-grow"
                      >
                        <div
                          className="cursor-pointer flex justify-center items-center px-3"
                          onClick={() => {
                            modalStore.setModal(MODAL_NAME_RESERVATION, {
                              isEdit: true,
                              selected: reservation,
                            });
                          }}
                        >
                          <PencilAltIcon className="h-5 w-5 text-gray-500" />
                        </div>
                        <div
                          className="cursor-pointer flex justify-center items-center px-1"
                          onClick={() => {
                            //ablehnen
                            setCurrentReservation(reservation);
                            setNotArrivedModalOpen(true);
                          }}
                        >
                          <XIcon className="h-5 w-5 text-red-500" />
                        </div>
                        <div
                          className="cursor-pointer flex justify-center items-center px-3"
                          onClick={() => {
                            //ablehnen
                            setCurrentReservation(reservation);
                            setArrivedModalOpen(true);
                          }}
                        >
                          <CheckCircleIcon className="h-5 w-5 text-green-500" />
                        </div>
                      </div>
                    </div>
                  );
                }}
              />
            </div>
          );
        })}

        <Confirm
          title={t("reservation.arrivedTitle")}
          description={t("reservation.arrivedDescription")}
          isOpen={arrivedModalOpen}
          setOpen={setArrivedModalOpen}
          cancelText={t("reservation.arrivedCancel")}
          confirmText={t("reservation.arrivedConfirm")}
          onConfirm={() => {
            if (currentReservation) {
              reservationStore.setArrived(currentReservation._id, true);
            } else {
              toast.error("Internal error occurred");
            }
            setArrivedModalOpen(false);
          }}
          onCancel={() => {
            //cancel
            setCurrentReservation(undefined);
            setArrivedModalOpen(false);
          }}
        />
        <Confirm
          title={t("reservation.notArrivedTitle")}
          description={t("reservation.notArrivedDescription")}
          isOpen={notArrivedModalOpen}
          setOpen={setNotArrivedModalOpen}
          cancelText={t("reservation.notArrivedCancel")}
          confirmText={t("reservation.notArrivedConfirm")}
          onConfirm={() => {
            if (currentReservation) {
              reservationStore.setArrived(currentReservation._id, false);
            } else {
              toast.error("Internal error occurred");
            }
            setNotArrivedModalOpen(false);
          }}
          onCancel={() => {
            //cancel
            setCurrentReservation(undefined);
            setNotArrivedModalOpen(false);
          }}
        />
      </div>
    </HomeLayout>
  );
};

export default observer(ReservationOverview);
