import {
  CalendarIcon,
  PhoneIcon,
  InboxIcon,
  UserIcon,
  DocumentIcon,
  ClockIcon,
} from "@heroicons/react/solid";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { NotificationStatus, ReservationDto } from "../../dto/Reservation.dto";
import reservationStore from "../../stores/reservation.store";
import Confirm from "../Confirm/Confirm";
import EmptyState from "../EmptyState/EmptyState";
import { LoadingAnimation } from "../Loading/Loading";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import TranslateReservationHeader from "../ReserverationDayItem/TranslateReservationHeader";
import SecondaryButton from "../SecondaryButton/SecondaryButton";
import restaurantStore from "../../stores/restaurant.store";
import { PencilAltIcon } from "@heroicons/react/outline";
import modalStore from "../../stores/modal.store";
import { MODAL_NAME_RESERVATION } from "../../components/ReservationModal/ReservationModal";

interface TableProps {
  reservations: ReservationDto[];
  reservationsStats?: Record<string, { count: number; totalPersons: number }>;
  emptyTitle: string;
  emptySubtitle: string;
  hideActions?: boolean;
  renderHours?: boolean;
}

interface TableEntryProps {
  reservation: ReservationDto;
  hideActions: boolean;
  setAcceptOpen: (open: boolean, reservation: ReservationDto) => void;
  setDeclineOpen: (open: boolean, reservation: ReservationDto) => void;
}

const TableEntry = ({
  reservation,
  hideActions,
  setAcceptOpen,
  setDeclineOpen,
}: TableEntryProps) => {
  const { t } = useTranslation();

  const handleAccept = async () => {
    setAcceptOpen(true, reservation);
  };

  const handleDecline = async () => {
    setDeclineOpen(true, reservation);
  };

  return (
    <li
      key={reservation.email}
      className="px-4 py-4 bg-white shadow sm:rounded-md"
    >
      <li
        key={reservation.email}
        className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
      ></li>

      <>
        <div className="flex justify-between">
          <p className="truncate text-m font-bold text-primary-600">
            {reservation.firstName + " " + reservation.lastName}
          </p>
          <PencilAltIcon
            className="w-5 h-5 text-gray-500 cursor-pointer"
            onClick={() => {
              modalStore.setModal(MODAL_NAME_RESERVATION, {
                isEditReservationsOpen: true,
                selected: reservation,
              });
            }}
          ></PencilAltIcon>
        </div>
        <div className={`flex pb-5 mt-3 ${hideActions ? "" : "border-b"}`}>
          <div className="flex min-w-0 flex-1 items-center">
            <div className="min-w-0 flex-1">
              <div>
                <p className="mt-2 flex items-center text-sm text-gray-500">
                  <CalendarIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <span>
                    {dayjs(reservation.dateTime).format("HH:mm DD.MM.YYYY")}
                  </span>
                </p>
                <p className="mt-2 flex items-center text-sm text-gray-500">
                  <ClockIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <span>{reservation.duration} Minuten</span>
                </p>
                <p className="mt-2 flex items-center text-sm text-gray-500">
                  <UserIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <span>{reservation.numberOfPersons}</span>
                </p>
                <p className="mt-2 flex items-center text-sm text-gray-500">
                  <InboxIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="mr-1.5">
                    <a
                      href={`mailto:${reservation.email}`}
                      className="underline"
                    >
                      {reservation.email}
                    </a>
                  </span>
                  <span
                    className={
                      reservation?.notifications?.[0]?.status ===
                      NotificationStatus.DELIVERED
                        ? "inline-flex items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"
                        : "inline-flex items-center rounded-full bg-red-50 px-1.5 py-0.5 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10"
                    }
                  >
                    {t(
                      "reservation.notificationStatus." +
                        reservation?.notifications?.[0]?.status
                    )}
                  </span>
                </p>
                {reservation.phoneNumber && (
                  <p className="mt-2 flex items-center text-sm text-gray-500">
                    <PhoneIcon
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <span>
                      <a
                        href={`tel:${reservation.phoneNumber}`}
                        className="underline"
                      >
                        {reservation.phoneNumber}
                      </a>
                    </span>
                  </p>
                )}
                {reservation.note ? (
                  <p className="mt-2 flex items-center text-sm text-gray-500">
                    <DocumentIcon
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <span>{reservation.note}</span>
                  </p>
                ) : (
                  <br></br>
                )}
              </div>
            </div>
          </div>
        </div>
        {!hideActions && (
          <div className="flex flex-row items-end justify-end mt-5">
            <SecondaryButton
              title={t("reservation.reject")}
              onClick={() => {
                handleDecline();
              }}
            />
            <PrimaryButton
              title={t("reservation.accept")}
              className="ml-3"
              onClick={() => {
                handleAccept();
              }}
            />
          </div>
        )}
      </>
    </li>
  );
};

const Table = ({
  reservations,
  reservationsStats,
  hideActions = false,
  renderHours = false,
  emptyTitle,
  emptySubtitle,
}: TableProps) => {
  const [preparedReservations, setPreparedReservations] = useState<any>([]);
  const [acceptOpen, setAcceptOpen] = useState(false);
  const [declineOpen, setDeclineOpen] = useState(false);
  const [currentReservation, setCurrentReservation] = useState<any>(undefined);

  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    const tmp: any = {};

    for (let reservation of reservations) {
      let key = dayjs(reservation.dateTime)
        .set("minute", 0)
        .set("second", 0)
        .set("millisecond", 0)
        .toISOString();

      if (!renderHours) {
        key = dayjs(reservation.dateTime)
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
          .set("millisecond", 0)
          .toISOString();
      }

      if (!tmp[key]) {
        tmp[key] = [];
      }
      tmp[key].push(reservation);
    }
    setPreparedReservations(tmp);
  }, [reservations, renderHours]);

  if (!reservations || reservations.length === 0) {
    return (
      <div className="mt-5">
        <EmptyState title={emptyTitle} subtitle={emptySubtitle} />
      </div>
    );
  }

  const renderInfoMessage = (date: string) => {
    const dateKey = dayjs(date).format("YYYY-MM-DD");
    const stat = reservationsStats?.[dateKey];

    return stat ? (
      <TranslateReservationHeader reservationStats={stat} />
    ) : (
      <Trans i18nKey="reservation.statInfoNoReservations" />
    );
  };

  const renderDay = () => {
    return Object.keys(preparedReservations)
      .reverse()
      .map((key, index) => {
        return (
          <div key={key} className={index !== 0 ? "pt-8" : ""}>
            <div className="flex items-center">
              <h2 className="text-xl font-bold text-gray-70 px-8">
                {renderHours
                  ? `${dayjs(key).format("HH")}:00`
                  : dayjs(key).format("DD.MM.YYYY")}
              </h2>
              <p>{renderInfoMessage(key)}</p>
            </div>
            <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 px-8 pt-5">
              {preparedReservations[key].map((reservation: ReservationDto) => {
                return (
                  <TableEntry
                    key={reservation._id}
                    reservation={reservation}
                    hideActions={hideActions}
                    setAcceptOpen={(
                      open: boolean,
                      reservation: ReservationDto
                    ) => {
                      setCurrentReservation(reservation);
                      setAcceptOpen(open);
                    }}
                    setDeclineOpen={(
                      open: boolean,
                      reservation: ReservationDto
                    ) => {
                      setCurrentReservation(reservation);
                      setDeclineOpen(open);
                    }}
                  />
                );
              })}
            </ul>
          </div>
        );
      });
  };

  if (loading) return <LoadingAnimation />;

  return (
    <div className="overflow-hidden pb-3">
      <ul>{renderDay()}</ul>
      <Confirm
        enableTimeLimit={
          restaurantStore.currentRestaurant &&
          restaurantStore.currentRestaurant.enableReservationLimit
        }
        isOpen={acceptOpen}
        setOpen={setAcceptOpen}
        title={t("reservation.confirmTitle")}
        description={t("reservation.confirmDescription")}
        confirmText={t("reservation.confirmText")}
        onConfirm={async (data?: any) => {
          if (!currentReservation) return;
          const acceptNote = data ? data.text : "";
          const limitTime = data.limitReservation;
          console.log(data);
          setLoading(true);
          await reservationStore.acceptReservation(
            currentReservation._id,
            limitTime,
            acceptNote
          );
          setLoading(false);
          setAcceptOpen(false);
        }}
        cancelText={t("reservation.cancelText")}
        enableText
        onCancel={() => {
          setAcceptOpen(false);
        }}
        placeholder={t("reservation.acceptTextAreaPlaceholder")}
      />
      <Confirm
        isOpen={declineOpen}
        setOpen={setDeclineOpen}
        title={t("reservation.confirmCancelTitle")}
        description={t("reservation.confirmCancelDescription")}
        confirmText={t("reservation.confirmCancelText")}
        cancelText={t("reservation.cancelText")}
        onConfirm={async (data?: any) => {
          if (!currentReservation) return;
          const reason = data ? data.text : "";

          setLoading(true);
          await reservationStore.rejectReservation(
            currentReservation._id,
            reason
          );
          setLoading(false);
          setDeclineOpen(false);
        }}
        onCancel={() => {
          setDeclineOpen(false);
        }}
        enableText
        placeholder={t("reservation.cancelTextAreaPlaceholder")}
      />
    </div>
  );
};

export default Table;
