import React from "react";
import { useTranslation } from "react-i18next";

interface TextAreaProps {
  register: any;
  title?: string;
  placeholder: string;
  disabled?: boolean;
  hideTitle?: boolean;
}

const TextArea = ({
  register,
  title,
  disabled,
  placeholder,
  hideTitle,
}: TextAreaProps) => {
  const { t } = useTranslation();
  return (
    <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-primary-600 focus-within:border-primary-600">
      {!hideTitle && (
        <label
          htmlFor="name"
          className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
        >
          {title ? t(title) : ""}
        </label>
      )}
      <textarea
        {...register}
        disabled={disabled}
        rows={3}
        className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm focus:outline-none"
        placeholder={t(placeholder)}
      />
    </div>
  );
};

export default TextArea;
