import { inject } from "mobx-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RestaurantStore } from "../../stores/restaurant.store";

interface Props {
  restaurantStore?: RestaurantStore;
  children: any;
}

const AuthContainer = ({ children, restaurantStore }: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    const jwt = localStorage.getItem("essen24_jwt");
    if (!jwt) {
      navigate("/", { replace: true });
    }
  }, [navigate]);

  useEffect(() => {
    restaurantStore?.getRestaurants();
  }, [restaurantStore]);

  return <div>{children}</div>;
};

export default inject("restaurantStore")(AuthContainer);
