import { inject, observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { ModalStore } from "../../stores/modal.store";
import DatePicker from "../DatePicker/DatePicker";
import DayPicker from "../DayPicker/DayPicker";
import CardHeading from "../ModalCard/CardHeading";
import ModalCard from "../ModalCard/ModalCard";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import TextArea from "../TextArea/TextArea";
import TextField from "../TextField/TextField";
import { useForm } from "react-hook-form";
import { prepareData, ShiftStore } from "../../stores/shift.store";
import SameDayReservation from "../SameDayReservation/SameDayReservation";

export const MODAL_NAME_CREATE_SHIFT = "CreateShiftModal";

interface CreateShiftModalProps {
  modalStore?: ModalStore;
  shiftStore?: ShiftStore;
}

const CreateShiftModal = ({
  modalStore,
  shiftStore,
}: CreateShiftModalProps) => {
  const { t } = useTranslation();
  const { register, handleSubmit, control, reset, setValue, getValues } = useForm({
    defaultValues: {
      name: undefined,
      capacity: 50,
      interval: 30,
      duration: 90,
      notes: undefined,
      firstSeating: undefined,
      lastSeating: undefined,
    },
  });

  if (modalStore?.currentModal !== MODAL_NAME_CREATE_SHIFT) {
    return <></>;
  }

  const onSubmit = async (data: any) => {
    const shift = prepareData(data);
    if (await shiftStore?.createShift(shift)) {
      reset();
      modalStore?.setModal("");
    }
  };

  return (
    <ModalCard isOpen={modalStore?.currentModal === MODAL_NAME_CREATE_SHIFT}>
      <div className="absolute top-0 right-0 left-0 bottom-0 overflow-auto">
        <div className="bg-white p-5">
          <CardHeading
            title={t("createShift.title")}
            className="fixed top-0 left-0 right-0 bg-white p-3 z-10"
          />
          <form
            className="space-y-8 overflow-hidden"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="space-y-8  sm:space-y-5 mt-16">
              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5">
                  <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    {t("createShift.name")}
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <TextField
                      register={register("name", {
                        required: true,
                        pattern: /[a-zA-Z]+/,
                        min: 3,
                        max: 30,
                      })}
                      placeholder="Name"
                      title=""
                      hideTitle
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
                  <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    {t("createShift.capacity")}
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <TextField
                      register={register("capacity", {
                        required: true,
                        pattern: /[0-9]+ /,
                        valueAsNumber: true,
                      })}
                      placeholder={t("createShift.capacityDetails")}
                      title=""
                      hideTitle
                    />
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
                  <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    {t("createShift.interval")}
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <TextField
                      register={register("interval", {
                        pattern: /[0-9]+ /,
                        valueAsNumber: true,
                        required: true,
                      })}
                      placeholder={t("createShift.intervalDetails")}
                      title=""
                      hideTitle
                    />
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
                  <label className="block text-sm font-medium text-gray-700  sm:pt-2">
                    {t("createShift.duration")}
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <TextField
                      register={register("duration", {
                        pattern: /[0-9]+ /,
                        valueAsNumber: true,
                        required: true,
                      })}
                      placeholder={t("createShift.durationDetails")}
                      title=""
                      hideTitle
                    />
                  </div>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
                <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  {t("createShift.notes")}
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <TextArea
                    register={register("notes", {
                      pattern: /[a-zA-Z]+/,
                    })}
                    placeholder={t("createShift.notesPlaceholder")}
                    hideTitle
                  />
                </div>
              </div>
            </div>
            <div className="mt-5 border-t pt-5 flex-wrap">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                {t("createShift.timeDate")}
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                {t("createShift.timeDateDetails")}
              </p>
            </div>
            <DayPicker
              rules={{ required: true }}
              control={control}
              fieldName="daysOfWeek"
            />
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <label className="block text-sm font-medium text-gray-700">
                  {t("createShift.validFrom")}
                </label>
                <DatePicker
                  fieldName="startDate"
                  rules={{ required: true }}
                  control={control}
                  defaultValue={new Date()}
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label className="block text-sm font-medium text-gray-700">
                  {t("createShift.validTo")}
                </label>
                <DatePicker fieldName="endDate" rules={{}} control={control} />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label className="block text-sm font-medium text-gray-700">
                  {t("createShift.firstSeating")}
                </label>
                <TextField
                  register={register("firstSeating", {
                    required: true,
                  })}
                  placeholder={t("createShift.firstSeatingPlaceholder")}
                  title=""
                  type="time"
                  hideTitle
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label className="block text-sm font-medium text-gray-700">
                  {t("createShift.lastSeating")}
                </label>
                <TextField
                  register={register("lastSeating", {
                    required: true,
                  })}
                  placeholder={t("createShift.lastSeatingPlaceholder")}
                  title=""
                  type="time"
                  hideTitle
                />
              </div>
            </div>
            <div className="mt-5 border-t pt-5">
              <SameDayReservation
                control={control}
                register={register}
                getValues={getValues}
                setValue={setValue}
                edit={true}
                name="sameDayReservation"
              ></SameDayReservation>
            </div>
            <div className="flex justify-end">
              <PrimaryButton
                submit
                title={t("createShift.save")}
                className="relative inline-flex items-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              />
            </div>
          </form>
        </div>
      </div>
    </ModalCard>
  );
};

export default inject("modalStore", "shiftStore")(observer(CreateShiftModal));
