import React from "react";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { Controller } from "react-hook-form";

interface Props {
  fieldName: string;
  options: any;
  rules: any;
  control: any;
}

const DropDown = ({ options, rules, control, fieldName }: Props) => {
  return (
    <Controller
      name={fieldName}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Dropdown
          options={options}
          onChange={(slected) => {
            field.onChange(slected.value);
          }}
          value={field.value}
          placeholder="Select an option"
        />
      )}
    />
  );
};

export default DropDown;
